<template>
  <div>
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
            class="pt-0"
          >
            <v-date-range-picker
              v-model="filters.range"
              label="Data"
              dark
              @change="loadShipments"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="4"
            class="pt-0"
          >
            <v-text-field
              v-model="filters.search"
              label="Pesquisar"
              prepend-inner-icon="search"
              dark
              filled
              hide-details
              clearable
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="filteredItems"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, -1]
        }"
        mobile-breakpoint="1000"
        class="elevation-1"
        show-select
      >
        <template #item.driver.name="{ value }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ truncate(value || '', 15).toUpperCase() }}
              </v-chip>
            </template>

            {{ value }}
          </v-tooltip>
        </template>

        <template #item.operation="{ item }">
          <v-chip small>
            <v-avatar left>
              <v-icon
                v-if="item.operation === 'DESCARGA'"
                color="red"
                v-text="'arrow_circle_up'"
              />
              <v-icon
                v-if="item.operation === 'CARGA'"
                color="green"
                v-text="'arrow_circle_down'"
              />
            </v-avatar>

            <span>
              {{ item.operation }}
            </span>
          </v-chip>
        </template>

        <template #[`item.operationTime`]="{ value }">
          <v-chip
            v-if="value"
            small
          >
            {{ formatDate(value, 'DD/MM/YYYY HH:mm') }}
          </v-chip>
        </template>

        <template #[`item.operations`]="{ item }">
          <shipment-operations
            :shipment="item"
            @showShipmentLoad="onShowShipmentLoad"
            @showShipmentPacking="onShowShipmentPacking"
            @reloadShipments="loadShipments"
          />
        </template>

        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn
                dark
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="onPrint([item])"
              >
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir Romaneio
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="printSheet(item)"
              >
                <v-list-item-icon>
                  <v-icon>assignment</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Imprimir Fichas Pallets
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasRevertAccess"
                @click="revertShipment(item)"
              >
                <v-list-item-icon>
                  <v-icon>undo</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Retornar para a Fila
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>

    <shipment-load-dialog
      ref="shipmentLoad"
      @close="loadShipments"
    />

    <shipment-packing-dialog
      ref="shipmentPacking"
      @close="loadShipments"
    />

    <print-settings-dialog
      ref="printSettings"
      @print="print"
    />

    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="selectedItems.length > 0"
        color="orange darken-1"
        dark
        fab
        @click="onPrint(selectedItems)"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>
          Imprimir Romaneio
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, getCurrentInstance } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'
import _ from 'lodash'
import moment from 'moment'
import printJS from 'print-js'
import { usePermissions } from '@/Support/Composables/permissions.js'

import VDateRangePicker from '@/Support/Components/VDateRangePicker.vue'
import ShipmentOperations from '@/Domains/Shipment/Shipment/Components/ShipmentOperations.vue'
import ShipmentLoadDialog from '@/Domains/Shipment/Shipment/Components/ShipmentLoadDialog.vue'
import ShipmentPackingDialog from '@/Domains/Shipment/Shipment/Components/ShipmentPackingDialog.vue'
import PrintSettingsDialog from '@/Support/Components/PrintSettingsDialog.vue'

import api from '@/Domains/Shipment/Api/Shipment.js'

const { progressBar, notify, confirm } = useUtils()

const { proxy } = getCurrentInstance()

const { hasPermission } = usePermissions()

const hasRevertAccess = computed(() => hasPermission('shipment-revert'))

const filters = reactive({
  range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  search: '',
})

const items = ref([])

const selectedItems = ref([])

const headers = computed(() => {
  if (proxy.$vuetify.breakpoint.md) {
    return [
      { align: 'start', text: 'Motorista', value: 'driver.name' },
      { text: 'Placa', value: 'vehicle.plate' },
      { text: 'Origem/Destino', value: 'route.description' },
      { align: 'end', text: '', value: 'operations', width: 320, sortable: false },
      { align: 'end', text: '', value: 'actions', width: 50, sortable: false },
    ]
  }

  return [
    { align: 'start', text: 'Código', value: 'code' },
    { align: 'start', text: 'Motorista', value: 'driver.name' },
    { text: 'Operação', value: 'operation' },
    { text: 'Placa', value: 'vehicle.plate' },
    { text: 'Data/Hora Operação', value: 'operationTime', width: 165 },
    { text: 'Origem/Destino', value: 'route.description' },
    { align: 'end', text: '', value: 'operations', width: 340, sortable: false },
    { align: 'end', text: '', value: 'actions', width: 50, sortable: false },
  ]
})

const filteredItems = computed(() => {
  if (!filters.search) {
    return items.value
  }

  const search = filters.search.toUpperCase().trim()

  return items.value.filter(item => {
    const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search)
    return hasSearch
  })
})

const loadShipments = async () => {
  try {
    progressBar?.loading()

    const [startDate, endDate] = filters.range;

    items.value = await api.index({
      status: ['FINALIZADO'],
      start_date: startDate,
      end_date: endDate,
    })
  } catch (err) {
    notify.error(
      'Oops, ocorreu um erro ao carregar os carregamentos!',
      'Atenção'
    )

    console.log(err)
  } finally {
    progressBar?.hide()
  }
}

const shipmentLoad = ref()
const shipmentPacking = ref()

const onShowShipmentLoad = (shipment) => {
  shipmentLoad.value.show(shipment)
}

const onShowShipmentPacking = (shipment) => {
  shipmentPacking.value.show(shipment)
}

const revertShipment = async (shipment) => {
  if (!(await confirm(
    'Atenção',
    `Deseja realmente reverter este carregamento?
      <br /> <br />
    - Rota: <b>${shipment.route.description}</b>
      <br />
    - Motorista: <b>${shipment.driver.name}</b>
      <br />
    - Veiculo: <b>${shipment.vehicle.plate || ''}</b>
    <br /> <br />`,
    { color: 'red', token: 'REVERTER' }
  ))) {
    return
  }

  try {
    progressBar?.loading()

    await api.update(shipment.id, { estado: 'PROCESSANDO' });

    notify.success('Expedição revertida com sucesso', 'Sucesso');

    items.value = items.value.filter(item => item.id !== shipment.id)
  } catch (e) {
    console.log(e)

    notify.error(
      'Oops, ocorreu um erro ao excluir o carregamento!',
      'Atenção'
    )
  } finally {
    progressBar?.hide()
  }
}

const printSettings = ref()

const onPrint = (items) => {
  const ids = items.map(item => item.id)

  printSettings.value.show({
    item: { ids },
    params: [
      { title: 'Termo de Responsabilidade - Controle de Carga', key: 'mostra_termo', value: false },
    ]
  })
}

const print = async ({ item: { ids },  params }) => {
  try {
    progressBar?.loading()

    const printable = await api.print({ ids, params })

    return printJS({
      printable,
      type: 'pdf',
      base64: true,
    })
  } catch (e) {
    console.warn(e)
    notify.error(
      'Oops, ocorreu um erro ao imprimir os romaneios!',
      'Atenção'
    )
  } finally {
    progressBar?.hide()
  }
}

const printSheet = async ({ id }) => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/pallet/sheet`, { params: {
      shipment_id: id,
    } })

    return printJS({
      printable: data,
      type: 'raw-html'
    })
  } catch (e) {
    console.warn(e)
    notify.error(
      'Oops, ocorreu um erro ao imprimir as fichas!',
      'Atenção'
    )
  } finally {
    progressBar?.hide()
  }
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const truncate = (value, length) => _.truncate(value, { length })

onMounted(() => {
  loadShipments()
})
</script>
