<template>
  <div class="mx-6 px-16 pt-0 pb-7">
    <v-flex
      xs12
      class="pt-4 mb-4 text-xs-center"
    >
      <h2 class="menu-header white--text">
        Produtores Ativos
      </h2>
      <v-btn
        text
        @click="$router.back()"
      >
        <v-icon>arrow_left</v-icon>
        <div class="pr-3">
          Voltar
        </div>
      </v-btn>
    </v-flex>

    <v-card
      dark
      class="v-tamanho report-card"
      color="transparent"
    >
      <v-card-title class="py-1 d-block">
        <v-row>
          <v-col
            md="4"
          >
            <producer-event-filter
              v-model="filters.event"
            />
          </v-col>
          <v-spacer />
          <v-col
            md="4"
          >
            <v-text-field
              v-model="filters.search"
              append-icon="search"
              label="Busca"
              single-line
              hide-details
              clearable
              filled
            />
          </v-col>

          <filter-menu
            :customs="headers"
            :selected-customs.sync="filters.selectedCustoms"
          />
        </v-row>

        <filter-items
          :selected-customs.sync="filters.selectedCustoms"
        />
      </v-card-title>

      <data-table
        ref="report"
        name="Produtores Ativos"
        :headers="headers"
        :items="filteredProducers"
        :loading="loading"
        :sort-by.sync="filters.sort.by"
        :sort-desc.sync="filters.sort.desc"
        dark
        show-custom-group
        @click:row="onEditProducer"
      >
        <template #[`item.nome`]="{ item }">
          {{ item.nome }}
          <v-icon
            v-if="item.id_condominio"
            color="rgba(255, 255, 255, 0.7)"
          >
            groups
          </v-icon>
        </template>

        <template #[`item.tipo_ordenha`]="{ item }">
          <span v-if="item.tipo_ordenha">  {{ getMilkingType(item.tipo_ordenha) }} </span>
        </template>
        <template #[`item.data_cadastro_app`]="{ value }">
          <v-tooltip
            v-if="value"
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                small
                class="ma-2"
                v-on="on"
              >
                <v-icon>
                  phone_iphone
                </v-icon>
              </v-btn>
            </template>
            {{ value }}
          </v-tooltip>
          <v-chip
            v-else
            class="ma-2"
            outlined
            x-small
          >
            -
          </v-chip>
        </template>

        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="hasInsertUpdateAccess"
                @click="onEditProducer(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasUnlinkAccess"
                @click="onChangeProducerStatus(item, 'DESVINCULADO')"
              >
                <v-list-item-icon>
                  <v-icon>person_add_disabled</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Desvincular</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasSuspendAccess"
                @click="onChangeProducerStatus(item, 'SUSPENSO')"
              >
                <v-list-item-icon>
                  <v-icon>remove_circle_outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Suspender</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasPrintAccess"
                @click="printProducer(item.id_pessoa)"
              >
                <v-list-item-icon>
                  <v-icon>print</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Imprimir</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="hasDeleteAccess"
                @click="deleteProducer(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir produtor</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.id_rota"
                @click="printQrCodeProducer(item.id_pessoa)"
              >
                <v-list-item-icon>
                  <v-icon>qr_code_2</v-icon>
                </v-list-item-icon>
                <v-list-item-title>QR code coleta</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!hasDeleteAccess && !hasPrintAccess && !hasSuspendAccess && !hasUnlinkAccess && !hasInsertUpdateAccess"
              >
                <v-list-item-icon>
                  <v-icon>visibility_off</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Usuário sem permissões!</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template #[`footer.prepend`]>
          <div
            class="text-caption text-left ml-4"
          >
            Produtores:
            <v-chip
              small
              class="mx-2 mb-0"
            >
              {{ formatNumber(totalProducers) }}
            </v-chip>
          </div>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <div
                class="text-caption text-left ml-4"
                v-bind="attrs"
                v-on="on"
              >
                Média Litros:
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ formatNumber(litersAverage || 0) }} L
                </v-chip>
              </div>
            </template>
            Volume média dos ultimos 6 dias
          </v-tooltip>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="hasInsertUpdateAccess"
        fab
        color="#008890"
        @click="openRegisterProducer"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              person_add
            </v-icon>
          </template>
          Cadastrar Produtor
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="grey darken-1"
        @click="exportTxt()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              text_snippet
            </v-icon>
          </template>

          Download TXT
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="showTempPassword"
        fab
        dark
        color="grey darken-4"
        @click="generateTempPasswords()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              lock_reset
            </v-icon>
          </template>

          Gerar senhas para produtores do App
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="grey darken-4"
        @click="printQrCodeProducers()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              qr_code_2
            </v-icon>
          </template>

          Imprimir QrCodes
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="qrCodeDialog.isOpen"
      width="380"
    >
      <v-card>
        <v-card-title class="text-h5">
          Tamanho da impressão
        </v-card-title>
        <v-card-text>
          <v-btn-toggle
            v-if="qrCodeDialog.isOpen"
            class="d-flex justify-center"
          >
            <v-btn @click="printQrCode('100mm')">
              Etiqueta

              <v-icon
                right
                color="purple darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>

            <v-btn @click="printQrCode('A4_Alt')">
              Grande

              <v-icon
                right
                color="green darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>

            <v-btn @click="printQrCode('A4')">
              Padrão

              <v-icon
                right
                color="blue darken-1"
              >
                qr_code_2
              </v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-card-text>
      </v-card>
    </v-dialog>

    <dialog-producer
      v-if="producerDialog.show"
      :visible="producerDialog.show"
      :producer-id.sync="producerDialog.id"
      @close="onCloseProducer"
      @onSave="onSaveProducer"
      @pending="onPendingProducer"
      @keydown.esc="onCloseProducer"
    />

    <status-producer-dialog
      ref="statusProducerDialog"
      @save="onSaveProducerStatus"
    />

    <print-settings-dialog
      ref="print-settings"
      @print="onPrintProducer"
    />

    <compare-producer-dialog
      ref="compare-dialog"
    />

    <v-overlay v-model="loading">
      <v-card-text>
        Carregando
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<script>
import moment from "moment-timezone";
import _ from "lodash";
import printJS from "print-js";
import { sortItems } from 'vuetify/lib/util/helpers'

import DialogProducer from "@/Domains/Producer/Management/Components/Dialogs/InfoProducer.vue";
import StatusProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/StatusProducerDialog.vue";
import PrintSettingsDialog from "@/Support/Components/PrintSettingsDialog.vue";
import ProducerEventFilter from "@/Domains/Producer/Management/Components/ProducerEventFilter.vue";
import FilterMenu from "@/Support/Components/FilterMenu/FilterMenu.vue";
import FilterItems from "@/Support/Components/FilterMenu/FilterItems.vue";

import CompareProducerDialog from "@/Domains/Producer/Management/Components/Dialogs/CompareProducerDialog.vue";

export default {
  name: "ActiveProducers",

  components: {
    DialogProducer,
    StatusProducerDialog,
    CompareProducerDialog,
    PrintSettingsDialog,
    ProducerEventFilter,
    FilterMenu,
    FilterItems,
  },

  data() {
    return {
      loading: false,

      filters: {
        search: '',
        event: { type: null, date: null },
        selectedCustoms: [],
        sort: { by: [], desc: [] }
      },

      headers: [
        { text: "Último Acesso APP", value: "data_cadastro_app", align: "center", sortable: false, filterable: false },
        { text: "C. Laticínio", value: "codigo_laticinio", align: "center" },
        { text: "Produtor", value: "nome", align: "center" },
        { text: "CCS", value: "ccs", align: "center" },
        { text: "CPP", value: "cbt", align: "center" },
        { text: "Gordura", value: "gordura", align: "center" },
        { text: "Proteina", value: "proteina", align: "center" },
        { text: "Sif", value: "sif", align: "center" },
        { text: "Vol.Leite", value: "litros", align: "center" },
        { text: "Região", value: "regiao", align: "center" },
        { text: "Rota Principal de Coleta", value: "rota", align: "center" },
        { text: "Km Industria", value: "distancia_industria", align: "center" },
        { text: "Celular", value: "celular" },
        { text: "Telefone", value: "telefone" },
        { text: "CPF/CNPJ do Titular", value: "cnpj_cpf", align: "center" },
        { text: "Nº de Identidade (RG)", value: "rg", align: "center" },
        { text: "Nº Nirf", value: "nirf", align: "center" },
        { text: "CIDASC/IMA", value: "cidasc", align: "center" },
        { text: "Código NRP", value: "nrp", align: "center" },
        { text: "Inscrição Estadual", value: "inscricao_estadual", align: "center" },
        { text: "Número de Ordenhas", value: "numero_ordenha", align: "center" },
        { text: "Capacidade dos Resfriadores (L)", value: "capacidade_resf_inte", align: "center" },
        { text: "Janela de Coleta - Inicio", value: "hora_inicio_coleta", align: "center", filterable: false },
        { text: "Janela de Coleta - Final", value: "hora_fim_coleta", align: "center", filterable: false },
        { text: "Realizado Coleta à Cada (H)", value: "frequencia_coleta", align: "center" },
        { text: "Data de Nascimento", value: "data_nascimento", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YY'), filterable: false },
        { text: "Cadastro de Produtores rurais", value: "cad_pro", align: "center" },
        { text: "Cooperativa", value: "cooperativa", align: "center" },
        { text: "CEP", value: "end_cep", align: "center" },
        { text: "Estado", value: "end_estado", align: "center" },
        { text: "Cod. Cidade", value: "end_cidade_codigo", align: "center" },
        { text: "Cidade", value: "end_cidade", align: "center" },
        { text: "Rua", value: "end_rua", align: "center" },
        { text: "Número", value: "end_numero", align: "center" },
        { text: "Bairro", value: "end_bairro", align: "center" },
        { text: "Latitude", value: "end_latitude", align: "center", filterable: false },
        { text: "Longitude", value: "end_longitude", align: "center", filterable: false },
        { text: "Dia do Pagamento", value: "dia_pagamento", align: "center", filterable: false },
        { text: "Banco", value: "banco", align: "center" },
        { text: "Agência", value: "agencia_bancaria", align: "center" },
        { text: "Conta", value: "conta_bancaria", align: "center" },
        { text: "Forma de pagamento", value: "forma_pagamento", align: "center" },
        { text: "Configuração de Taxas", value: "config_taxas_local", align: "center", filterable: false },
        { text: "E-mail", value: "email", align: "center" },
        { text: "Tipo de Ordenha", value: "tipo_ordenha", align: "center" },
        { text: "Código SDA", value: "codigo_sda", align: "center" },
        { text: "Data Última Coleta", value: "data_ultima_coleta", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YYYY'), filterable: false },
        { text: "Data de Vinculação", value: "data_entrada_laticinio", align: "center", formatter: value => this.formatDate(value, 'DD/MM/YYYY'), filterable: false },
        { text: "Acessos", value: "pessoa_acesso", align: "center", sortable: false, show: false, filterable: false },
        { text: "Técnico", value: "tecnico", align: "center", sortable: false, show: false },
        { text: "Motorista", value: "motorista", align: "center", sortable: false, show: false },
        { text: "Técnico de Qualidade", value: "tecnico_qualidade", align: "center", sortable: false, show: false },
        { text: "Técnico de Compra", value: "tecnico_compra", align: "center", sortable: false, show: false },
        { text: "Estado Civil", value: "estado_civil" },
        { text: "", altText: "Opções", value: "action", sortable: false, align: "end", filterable: false },
      ],

      producers: [],

      producerDialog: {
        show: false,
        id: null
      },

      statusDialog: {
        type: 'unlink'
      },

      qrCodeDialog: {
        isOpen: false,
        ids: []
      }
    };
  },

  computed: {
    filteredProducers() {
      if (!(this.filters.event.type && this.filters.event.date) && !this.filters.search && !this.filters.selectedCustoms.some(filter => filter.search)) {
        return this.producers;
      }

      const eventType = this.filters.event.type;
      const eventDate = this.filters.event.date;

      const eventFilters = {
        COLETADO: producer => producer.data_ultima_coleta >= eventDate,
        NAO_COLETADO: producer => producer.data_ultima_coleta < eventDate,
        VINCULADOS_A_PARTIR: producer => producer.data_entrada_laticinio >= eventDate,
      }

      const hasEventFilter = eventFilters[eventType] && eventDate

      const search = this.filters.search.toUpperCase().trim()

      const filterableColumns = this.headers.filter(header => header.filterable !== false).map(header => header.value)

      const searchFilter = (item) => {
        const searchTerm = search.toUpperCase()
        return filterableColumns.some(column => item[column]?.toString()?.toUpperCase()?.includes(searchTerm))
      }

      const customs = this.filters.selectedCustoms
        .filter(filter => filter.search)
        .map(filter => ({ value: filter.value, search: filter.search.toUpperCase().trim() }))

      const customsFilter = (item) => customs.every(filter => item[filter.value]?.toString()?.toUpperCase()?.includes(filter.search))

      return this.producers.filter(producer => {
        const matchLastCollect = !hasEventFilter || eventFilters[eventType](producer)
        const matchSearch = !search || searchFilter(producer)
        const matchCustoms = customs.length === 0 || customsFilter(producer)

        return matchLastCollect && matchSearch && matchCustoms
      });
    },

    totalProducers() {
      return this.filteredProducers.length;
    },

    litersAverage() {
      const litros = Math.abs(this.filteredProducers.reduce((acc, item) => (acc + parseInt(item.litros || 0)), 0));
      return litros / this.totalProducers;
    },

    /**
     * Permissões de tela
     */
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasInsertUpdateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-insert-edit" && o.tipo === "COMPONENTE");
    },

    hasDeleteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-delete" && o.tipo === "COMPONENTE");
    },

    hasSuspendAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-suspend" && o.tipo === "COMPONENTE");
    },

    hasUnlinkAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-unlink" && o.tipo === "COMPONENTE");
    },

    hasPrintAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "producer-print" && o.tipo === "COMPONENTE");
    },

    showTempPassword() {
      return this.$store.state.settings.gerais.login_produtor_requer_senha || false;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(value) {
        const filterId = this.$options._componentTag;
        this.$store.commit("setFilter", { [filterId]: value });
      }
    }
  },

  created() {
    this.getFiltersFromStore();
  },

  mounted() {
    this.loadProducers();
  },

  methods: {
    getFiltersFromStore() {
      const filterId = this.$options._componentTag;
      if (filterId in this.$store.state.settings.filters) {
        this.filters = {
          ...this.filters,
          ...this.$store.state.settings.filters[filterId],
        };
      }
    },

    async loadProducers() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/produtores/listaProdutoresAtivos`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.producers = data.map(producer => {
          producer.pessoa_acesso = producer.pessoa_acesso ? JSON.parse(producer.pessoa_acesso).map(p => p.nome_usuario).join(', ') : ''
          return producer;
        });
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    onChangeProducerStatus(producer, status) {
      const Title = {
        'DESVINCULADO': 'Desvincular Produtor',
        'SUSPENSO': 'Suspender Produtor',
      };

      return this.$refs.statusProducerDialog.show({
        title: Title[status] || 'Status Produtor',
        producer: {
          id: producer.id_pessoa,
          name: producer.nome,
        },
        form: {
          status: status,
          date: moment().toDate(),
          params: {
            showStatusSelect: false,
          },
        },
      });
    },

    onSaveProducerStatus() {
      this.loadProducers();
    },

    /**
     * Abre o dialog de edição de produtor
     */
    onEditProducer(producer) {
      if (!this.hasInsertUpdateAccess) {
        return;
      }

      this.producerDialog = {
        show: true,
        id: producer.id_pessoa
      };
    },

    /**
     * Fecha dialog de edição de produtor
     */
    onCloseProducer() {
      this.producerDialog = {
        show: false,
        id: null
      };
    },

    /**
     * Fecha dialog de edição e carrega os produtores
     */
    onSaveProducer() {
      this.producerDialog = {
        show: false,
        id: null
      };
      this.loadProducers();
    },

    onPendingProducer(id) {
      this.$refs['compare-dialog'].show({ id });
    },

    /**
     * Abre cadatro do produtor
     */
    openRegisterProducer() {

      // Verifica se o usuário tem permissão de update
      if (!this.hasInsertUpdateAccess) {
        return true;
      }

      this.producerDialog = {
        show: true,
        id: null
      };
    },

    getReportTitle() {
      let title = `Produtores Ativos`;

      if (this.filters.event.type && this.filters.event.date) {
        if (this.filters.event.type == 'COLETADO') {
          title += ` - Coletados até dia `;
        }
        else if (this.filters.event.type == 'VINCULADOS_A_PARTIR') {
          title += ` - Vinculados a partir de `;
        }
        else {
          title += ` - Sem coletas a partir de `;
        }
        title += moment(this.filters.event.date).format('DD.MM.YY');
      }
      return title;
    },

    exportExcel() {
      const filename = this.getReportTitle();

      this.$refs.report.exportExcel(null, filename);
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title, true);
    },

    exportTxt() {
      const frequency = { '48': 1, '36': 1, '24': 2, '12': 2 };

      const contents = this.filteredProducers.map(producer => {
        return [
          producer.codigo_laticinio,
          '0-i',
          `${producer.litros || 0}-${producer.capacidade_resf_inte || 0}`,
          frequency[producer.frequencia_coleta] || 0,
          producer.nome,
        ].join(';');
      }).join('\r\n');

      const title = this.getReportTitle();

      let pp = document.createElement('a');
      pp.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(contents));
      pp.setAttribute('download', `${title}.txt`);
      pp.click();
    },

    printProducer(id) {
      this.$refs['print-settings'].show({
        item: {
          id
        },
        params: [
          { title: 'Endereço', key: 'mostra_endereco', value: true },
          { title: 'Dados de Pagamento', key: 'mostra_dados_pagamento', value: true },
          { title: 'Dados de Produção', key: 'mostra_dados_producao', value: true },
          { title: 'Checklist Prospecção', key: 'mostra_checklist', value: false },
          { title: 'Documentos assinados', key: 'mostra_documentos_assinados', value: false },
          { title: 'Preço/Negociação', key: 'mostra_negociacoes_preco', value: false },
          { title: 'Visita Prospecção', key: 'mostra_ultima_visita', value: false },
          { title: 'Dados de cônjuges', key: 'mostra_dados_conjuges', value: false },
        ]
      });
    },

    async onPrintProducer({ item,  params }) {
      try {
        this.loading = true;

        if (_.isEmpty(item.id)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/produtores/printDetalheProdutor`,
          {
            id_produtor: item.id,
            params
          }
        );

        return printJS({
          printable: data,
          type: 'pdf',
          base64: true,
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Método para exclusão do produtor
     * @param {Object} producer
     */
    async deleteProducer(producer) {
      try {
        if (!(await this.$root.$confirm('Atenção', `Realmente deseja <b> Excluir </b>o(a) produtor(a) <b>${producer.nome}</b> ?`, { color: 'red' }))) {
          return;
        }

        this.loading = true;

        if (!producer.id_pessoa) {
          throw "Produtor não informado";
        }

        const data = await this.$axios.post(
          `/produtores/deleteProdutor`,
          this.$qs.stringify({
            id_produtor: producer.id_pessoa,
          })
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }

        if (data.codigo === 0) {
          throw "Erro ao deletar produtor";
        }

        this.$snotify.success("Produtor deletado com sucesso!", "Sucesso");
        this.loadProducers();

      } catch (error) {
        console.log(error);

        this.$snotify.error("Erro ao deletar produtor ", "Atenção!!");
      } finally {
        this.loading = false;
      }
    },

    printQrCodeProducer(id) {
      this.qrCodeDialog.isOpen = true;
      this.qrCodeDialog.ids = [ id ];
    },

    printQrCodeProducers() {
      this.qrCodeDialog.isOpen = true;
      this.qrCodeDialog.ids = sortItems(this.filteredProducers, this.filters.sort.by, this.filters.sort.desc, 'pt-BR').map(producer => producer.id_pessoa);
    },

    async printQrCode(size) {
      try {
        this.qrCodeDialog.isOpen = false;
        this.$root.$progressBar.loading();

        const ids = this.qrCodeDialog.ids;

        const { data } = await this.$axios.post('/producer/tags', { ids, size })

        return printJS({
          printable: data,
          type: 'raw-html'
        })
      } catch (e) {
        this.$snotify.error('Oops, ocorreu um erro ao imprimir as etiquetas!', 'Atenção');
        console.error(e)
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async generateTempPasswords() {
      try {
        this.$root.$progressBar.loading();

        await this.$axios.get(`/producer/generate-temp-passwords`);

        this.$snotify.success("Senhas geradas com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao gerar as senhas!", "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value),
    formatDate: (value, format) => !value ? '-' : moment(value).format(format),

    getMilkingType(item) {
      switch (item) {
        case 'Manual':
          return 'ORDENHA MANUAL';
        case 'Mecanizada':
          return 'ORDENHA MECANIZADA';
      }
    },

  },
};
</script>
