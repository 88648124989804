<template>
  <div class="mx-6 px-16">
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Bonificações / Penalizações
        </h2>
        <bonus-product-name :raw-material-id="rawMaterialId" />
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <div>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg
          class="pt-0"
        >
          <CardInfo
            title="Cotação"
            icon="icon-bonificacao"
            :value="formatCurrency(baseValue)"
            :loading="loading"
          >
            <template #footer>
              <span
                style="font-size: 16px; line-height: 1rem; margin-top: 5px;"
                class="headline"
              >
                &nbsp;
              </span>
            </template>
          </CardInfo>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg
          class="pt-0"
        >
          <CardInfo
            title="Volume Total (Atual / Anterior)"
            icon="icon-coleta_principal"
            :loading="loading"
          >
            <template #value>
              <div
                class="headline"
              >
                <span>
                  {{ formatNumber(totalVol) }} L
                </span>
                <span>
                  /
                </span>
                <span
                  class="blue--text"
                  style="font-size: 16px; line-height: 1rem;"
                >
                  {{ formatNumber(lastTotalVol) }} L
                </span>
              </div>
            </template>

            <template #footer>
              <span
                style="font-size: 16px !important; line-height: 1rem; margin-top: 5px;"
                :class="{
                  'headline': true,
                  'green--text': totalVolDifference > 0,
                  'red--text': totalVolDifference < 0,
                }"
              >
                {{ formatNumber(totalVolDifference) }} L
              </span>
            </template>
          </CardInfo>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg
          class="pt-0"
        >
          <CardInfo
            title="Preço Médio Produtor / Litro (Atual / Anterior)"
            icon="icon-tipo_operacao"
            :loading="loading"
          >
            <template #value>
              <div
                class="headline"
              >
                <span>
                  {{ formatCurrency(milkPriceAvg) }}
                </span>
                <span>
                  /
                </span>
                <span
                  class="blue--text"
                  style="font-size: 16px; line-height: 1rem;"
                >
                  {{ formatCurrency(lastMilkPriceAvg) }}
                </span>
              </div>
            </template>

            <template #footer>
              <span
                style="font-size: 16px !important; line-height: 1rem; margin-top: 5px;"
                :class="{
                  'headline': true,
                  'green--text': milkPriceAvgDifference > 0,
                  'red--text': milkPriceAvgDifference < 0,
                }"
              >
                {{ milkPriceAvgDifference >= 0 ? '+ R$' : '- R$' }} {{ formatNumber(Math.abs(milkPriceAvgDifference), 4) }}
              </span>
            </template>
          </CardInfo>
        </v-col>

        <v-col
          v-if="hasPlatformCost"
          cols="12"
          sm="6"
          md="4"
          lg
          class="pt-0"
        >
          <CardInfo
            title="Custo Plataforma (Atual / Anterior)"
            icon="icon-frota"
            :loading="loading"
          >
            <template #value>
              <div
                class="headline"
              >
                <span>
                  {{ formatCurrency(platformCostAvg) }}
                </span>
                <span>
                  /
                </span>
                <span
                  class="blue--text"
                  style="font-size: 16px; line-height: 1rem;"
                >
                  {{ formatCurrency(lastPlatformCostAvg) }}
                </span>
              </div>
            </template>

            <template #footer>
              <span
                style="font-size: 16px !important; line-height: 1rem; margin-top: 5px;"
                :class="{
                  'headline': true,
                  'green--text': platformCostAvgDifference > 0,
                  'red--text': platformCostAvgDifference < 0,
                }"
              >
                {{ platformCostAvgDifference >= 0 ? '+ R$' : '- R$' }} {{ formatNumber(Math.abs(platformCostAvgDifference), 4) }}
              </span>
            </template>
          </CardInfo>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          lg
          class="pt-0"
        >
          <CardInfo
            title="Total à Pagar"
            icon="icon-quitacao_contas"
            :value="formatCurrency(totalPaidAmount)"
            :loading="loading"
          >
            <template #footer>
              <span
                style="font-size: 16px; line-height: 1rem; margin-top: 5px;"
                class="headline"
              >
                &nbsp;
              </span>
            </template>
          </CardInfo>
        </v-col>

        <v-col
          v-if="hasPlatformCost"
          cols="12"
          sm="6"
          md="4"
          lg
          class="pt-0"
        >
          <CardInfo
            title="Total Custo Plataforma"
            icon="icon-quitacao_contas"
            :value="formatCurrency(platformCost)"
            :loading="loading"
          >
            <template #footer>
              <span
                style="font-size: 16px; line-height: 1rem; margin-top: 5px;"
                class="headline"
              >
                &nbsp;
              </span>
            </template>
          </CardInfo>
        </v-col>
      </v-row>

      <div
        v-if="isAProjection"
        class="d-flex justify-center mt-2"
      >
        <v-alert
          dense
          light
          prominent
          colored-border
          border="left"
          elevation="2"
          type="warning"
          icon="insights"
          style="max-width: 900px"
        >
          <v-row
            class="pa-0"
            align="center"
          >
            <v-col class="grow py-0">
              <div class="text-h6">
                Modo de Projeção
              </div>
              <div class="body-1">
                Os resultados apresentados no modo de projeção utilizam a média diária do volume das últimas coletas do produtor.
              </div>
            </v-col>
            <v-col class="shrink py-0">
              <v-btn
                outlined
                color="primary"
                @click="closeProjection"
              >
                Fechar Projeção
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>

      <v-card
        dark
        class="bonus-list mt-3"
        color="transparent"
      >
        <v-card-title class="pa-0">
          <v-col cols="12">
            <v-row>
              <v-col
                cols="2"
                class="pb-0"
              >
                <v-menu
                  ref="menu"
                  transition="scale-transition"
                  offset-y
                  content-class="menu-select"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="dateRefFilter"
                      label="Mês de Referência"
                      prepend-inner-icon="event"
                      readonly
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="filters.date"
                    :max="maxDateRef"
                    type="month"
                    no-title
                    scrollable
                    @change="onDateFilterChange"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="2"
                class="pb-0"
              >
                <v-autocomplete
                  v-model="filters.route"
                  label="Escolher Rota"
                  :items="filters.routes"
                  prepend-inner-icon="icon-rota"
                  item-value="id_rota"
                  item-text="descricao"
                  multiple
                  clearable
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                cols="2"
                class="pb-0"
              >
                <v-autocomplete
                  v-model="filters.region"
                  label="Escolher Região"
                  :items="filters.regions"
                  prepend-inner-icon="map"
                  item-value="id_regiao"
                  item-text="descricao"
                  multiple
                  clearable
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                cols="2"
                class="pb-0"
              >
                <v-autocomplete
                  v-model="filters.person"
                  label="Escolher Técnico"
                  :items="filters.persons"
                  prepend-inner-icon="person"
                  item-value="id_pessoa"
                  item-text="nome"
                  clearable
                  :disabled="!hasRecountAccess"
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                v-if="hasProducersInCoop"
                cols="2"
                class="pb-0"
              >
                <v-select
                  v-model="filters.producerType"
                  prepend-inner-icon="person"
                  label="Tipo Produtores"
                  hide-details
                  clearable
                  :items="[
                    { text:'Todos', value:'TODOS' },
                    { text:'Cooperativa', value:'COOPERATIVA' },
                    { text:'Próprio', value:'PROPRIO' }
                  ]"
                  @change="onFilterChange"
                />
              </v-col>
              <v-col
                class="pb-0"
              >
                <v-text-field
                  v-model="filters.search"
                  label="Pesquisar"
                  prepend-inner-icon="search"
                  single-line
                  hide-details
                  clearable
                />
              </v-col>
            </v-row>
          </v-col>
        </v-card-title>

        <data-table
          ref="report"
          name="Bonificações / Penalizações"
          :headers.sync="headers"
          :items="filteredBonusList"
          :expanded.sync="selectedRow"
          item-key="id"
          class="elevation-1 bonus-list-table"
          show-expand
          single-expand
          :group-by="groupBy"
          :custom-sort="customSort"
          @update:page="onDataTablePaginationChange"
        >
          <template #[`group.header`]="{ group, isOpen, toggle, headers, items }">
            <template v-if="group">
              <td
                v-for="h in headers"
                :key="h.value"
                :class="h.align ? `text-${h.align}` : 'text-start'"
              >
                <v-btn
                  v-if="h.value === 'data-table-expand'"
                  icon
                  @click="toggle"
                >
                  <v-icon>
                    {{ isOpen ? 'remove' : 'add' }}
                  </v-icon>
                </v-btn>
                <template v-else-if="h.value === 'producer.name'">
                  {{ group }}
                </template>
                <template v-else-if="h.value === 'producer.city'">
                  {{ groups[group].city }}
                </template>
                <template v-else-if="h.value === 'rangeFinalPrice'">
                  {{ groups[group].rangeFinalPrice?.min?.toFixed(4) | Mask(CurrencyMask) }}
                  -
                  {{ groups[group].rangeFinalPrice?.max?.toFixed(4) | Mask(CurrencyMask) }}
                </template>
                <template v-else-if="h.value === 'result.totals.quality'">
                  {{ groups[group].quality | Mask(CurrencyMask) }}
                </template>
                <template v-else-if="h.value === 'result.totals.others'">
                  {{ groups[group].others | Mask(CurrencyMask) }}
                </template>
                <template v-else-if="h.value === 'lastFinalPrice'">
                  {{ groups[group].lastFinalPrice | Mask(CurrencyMask) }}
                </template>
                <template v-else-if="h.value === 'lastVol'">
                  {{ groups[group].lastVol | Mask(NumberMask) }} L
                </template>
                <template v-else-if="h.value === 'volDiff'">
                  {{ groups[group].volDiff | Mask(NumberMask) }} L
                </template>
                <template v-else-if="h.value === 'vol'">
                  {{ groups[group].vol | Mask(NumberMask) }} L
                </template>
                <template v-else-if="h.value === 'total'">
                  {{ groupTotals[group].total | Mask(CurrencyMask) }}
                </template>
                <template v-else-if="h.value === 'finalPrice'">
                  {{ groupTotals[group].finalPrice | Mask(CurrencyMask) }}
                </template>
                <template v-else-if="h.value === 'finalPriceDiff'">
                  {{ groupTotals[group].finalPriceDiff }}
                </template>
                <template v-else-if="h.value === 'complementaryAmount'">
                  {{ groupTotals[group].complementaryAmount | Mask(CurrencyMask) }}
                </template>
                <template v-else-if="h.value === 'dairyAdditionalAmount'">
                  <masked-input
                    v-model="groups[group].dairyAdditionalAmount"
                    class="text-body-2 mt-0 pt-0 pb-1"
                    hide-details
                    prefix="R$"
                    :mask="AdditionalMask"
                    currency
                    return-unmasked
                    :disabled="groups[group].locked || groups[group].financialLocked || !hasEditingPrice"
                    @input="onGroupPriceChanged(group, 'dairyAdditionalAmount')"
                  />
                </template>

                <template v-else-if="h.value === 'technicalAdditionalAmount'">
                  <masked-input
                    v-model="groups[group].technicalAdditionalAmount"
                    class="text-body-2 mt-0 pt-0 pb-1"
                    hide-details
                    prefix="R$"
                    :mask="AdditionalMask"
                    currency
                    return-unmasked
                    :disabled="groups[group].locked || groups[group].financialLocked || !hasEditingPriceTechnician"
                    @input="onGroupPriceChanged(group, 'technicalAdditionalAmount')"
                  />
                </template>
              </td>
            </template>
          </template>

          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col>
                  <v-card
                    color="transparent"
                    class="table-detail-container"
                    dark
                  >
                    <v-card-title
                      class="justify-center text-body-2"
                      style="background: rgba(0,0,0,.5);"
                    >
                      <span>Produtor</span>
                    </v-card-title>

                    <v-card-text class="pa-0 text-left">
                      <v-list color="rgba(0,0,0,.3)">
                        <v-list-item
                          dark
                          dense
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.producer.name }}
                            </v-list-item-title>

                            <v-list-item-subtitle>
                              # {{ item.producer.code }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    color="transparent"
                    class="table-detail-container"
                    dark
                  >
                    <v-card-title
                      class="justify-center text-body-2"
                      style="background: rgba(0,0,0,.5);"
                    >
                      <span>Resultados de Qualidade</span>
                    </v-card-title>

                    <v-card-text class="pa-0 text-left">
                      <v-list color="rgba(0,0,0,.3)">
                        <template v-for="(result, index) in item.result.quality">
                          <v-list-item
                            v-if="result.amount"
                            :key="index"
                            dark
                            dense
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ result.label }}: {{ result.result }}
                              </v-list-item-title>

                              <v-list-item-subtitle>
                                {{ result.amount.toFixed(4) | Mask(CurrencyMask) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    color="transparent"
                    class="table-detail-container"
                    dark
                  >
                    <v-card-title
                      class="justify-center text-body-2"
                      style="background: rgba(0,0,0,.5);"
                    >
                      <span>Adicionais</span>
                    </v-card-title>

                    <v-card-text class="pa-0 text-left">
                      <v-list color="rgba(0,0,0,.3)">
                        <template v-for="(result, index) in item.result.others">
                          <v-list-item
                            v-if="result.amount"
                            :key="index"
                            dark
                            dense
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ result.label }}<span v-if="result.result">: {{ result.result }}</span>
                              </v-list-item-title>

                              <v-list-item-subtitle>
                                {{ result.amount.toFixed(4) | Mask(CurrencyMask) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>

          <template #[`item.producer.name`]="{ item }">
            {{ item.producer.name }}
            <v-chip
              v-if="item.producer.status=='DESVINCULADO'"
              small
              color="orange"
            >
              {{ item.producer.status }}
            </v-chip>
          </template>

          <template #[`item.lastVol`]="{ value }">
            {{ value.toString() | Mask(NumberMask) }} L
          </template>

          <template #[`item.vol`]="{ value }">
            {{ value.toString() | Mask(NumberMask) }} L
          </template>

          <template #[`item.volDiff`]="{ value }">
            {{ value.toString() | Mask(NumberMask) }} L

            <v-icon
              v-if="value > 0"
              color="green accent-3"
            >
              trending_up
            </v-icon>
            <v-icon
              v-else-if="value < 0"
              color="red accent-3"
            >
              trending_down
            </v-icon>
            <v-icon
              v-else
              color="gray accent-3"
            >
              trending_flat
            </v-icon>
          </template>

          <template #[`item.result.others.brucelose.result`]="{ value }">
            {{ !value ? 'Vencido' : 'Não Vencido' }}
          </template>

          <template #[`item.result.others.tuberculose.result`]="{ value }">
            {{ !value ? 'Vencido' : 'Não Vencido' }}
          </template>

          <template #[`item.result.totals.quality`]="{ item }">
            {{ item.result.totals.quality.toFixed(4) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.result.totals.others`]="{ item }">
            {{ item.result.totals.others.toFixed(4) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.result.totals.taxes`]="{ value }">
            {{ value.toFixed(4) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.dairyAdditionalAmount`]="{ item }">
            <masked-input
              :ref="`dairyAdditionalAmount-${item.index}`"
              v-model="item.dairyAdditionalAmount"
              class="text-body-2 mt-0 pt-0 pb-1"
              hide-details
              prefix="R$"
              :mask="AdditionalMask"
              currency
              return-unmasked
              :disabled="item.locked || item.financialLocked || !hasEditingPrice"
              @input="onRowPriceChanged(item, 'dairyAdditionalAmount')"
              @onEnter="onInputEnter('dairyAdditionalAmount', item.index)"
            />
          </template>

          <template #[`item.technicalAdditionalAmount`]="{ item }">
            <masked-input
              :ref="`technicalAdditionalAmount-${item.index}`"
              v-model.number="item.technicalAdditionalAmount"
              class="text-body-2 mt-0 pt-0 pb-1"
              hide-details
              prefix="R$"
              :mask="AdditionalMask"
              currency
              return-unmasked
              :disabled="item.locked || item.financialLocked || !hasEditingPriceTechnician"
              @input="onRowPriceChanged(item, 'technicalAdditionalAmount')"
              @onEnter="onInputEnter('technicalAdditionalAmount', item.index)"
            >
              <template
                v-if="item.locked || item.financialLocked"
                #append
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >
                      lock
                    </v-icon>
                  </template>

                  Este registro está bloqueado para edição!
                </v-tooltip>
              </template>
            </masked-input>
          </template>

          <template #[`item.complementaryAmount`]="{ item }">
            <v-menu
              v-model="item.complementaryAmount.show"
              :close-on-content-click="false"
              :nudge-width="150"
            >
              <template #activator="{ on }">
                <div class="d-flex align-center justify-center">
                  <span>
                    {{ item.complementaryAmount.total.toFixed(4) | Mask(CurrencyMask) }}
                  </span>
                  <v-tooltip
                    v-if="item.locked || item.complementaryLocked"
                    top
                  >
                    <template #activator="{ on }">
                      <v-icon
                        small
                        class="ml-3"
                        v-on="on"
                      >
                        lock
                      </v-icon>
                    </template>

                    Este registro está bloqueado para edição!
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="hasEditingPrice"
                    top
                  >
                    <template #activator="{ on }">
                      <v-icon
                        small
                        class="ml-3"
                        v-on="on"
                        @click="item.complementaryAmount.show = true"
                      >
                        edit
                      </v-icon>
                    </template>

                    Adicionar valor complementar
                  </v-tooltip>
                </div>
              </template>

              <producer-complementary-menu
                :items.sync="item.complementaryAmount.items"
                :total.sync="item.complementaryAmount.total"
                :show.sync="item.complementaryAmount.show"
                @change="onRowPriceChanged(item, 'complementaryAmount')"
              />
            </v-menu>
          </template>

          <template #[`item.govSupportPerc`]="{ value }">
            {{ value.toFixed(4) | Mask(MilkCurrencyMask) }} %
          </template>

          <template #[`item.govSupportPrice`]="{ item }">
            <div class="d-flex align-center justify-center">
              <span>
                {{ getRowGovSupportPrice(item).toFixed(4) | Mask(CurrencyMask) }}
              </span>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    small
                    class="ml-3"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                Calculado com o Perc. Incentivo sobre a Cotação Paga
              </v-tooltip>
            </div>
          </template>

          <template #[`item.freightAmount`]="{ value }">
            <span>
              {{ value?.toFixed(4) | Mask(CurrencyMask) }}
            </span>
          </template>

          <template #[`item.invoiceFreightPrice`]="{ value }">
            <span>
              {{ value?.toFixed(4) | Mask(CurrencyMask) }}
            </span>
          </template>

          <template #[`item.lastNetPrice`]="{ value }">
            <span>
              {{ value?.toFixed(4) | Mask(CurrencyMask) }}
            </span>
          </template>

          <template #[`item.netPrice`]="{ item }">
            <span class="font-weight-bold">
              {{ getNetPrice(item).toFixed(4) | Mask(CurrencyMask) }}
            </span>
          </template>

          <template #[`item.platformPrice`]="{ item }">
            <div class="d-flex align-center justify-center">
              <span>
                {{ getPlatformPrice(item).toFixed(4) | Mask(CurrencyMask) }}
              </span>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    small
                    class="ml-3"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                Valor Líquido + Preço Frete
              </v-tooltip>
            </div>
          </template>

          <template #[`item.platformTotalPrice`]="{ item }">
            <span>
              {{ getPlatformTotalPrice(item).toFixed(4) | Mask(CurrencyMask) }}
            </span>
          </template>

          <template #[`item.finalPrice`]="{ item }">
            <div class="d-flex align-center justify-center">
              <span :class="{'red lighten-1 px-1 font-weight-bold': isFinalPriceOutOfRange(item)}">
                {{ getRowFinalPrice(item).toFixed(4) | Mask(CurrencyMask) }}
              </span>
              <v-tooltip
                v-if="!item.locked && !item.financialLocked && hasAccessEditingQuoteChanges"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    small
                    class="ml-3"
                    v-on="on"
                    @click.stop="showProducerFinalPriceAdjustimentDialog(item)"
                  >
                    <v-icon small>
                      edit
                    </v-icon>
                  </v-btn>
                </template>

                Editar cotação paga
              </v-tooltip>
            </div>
          </template>

          <template #[`item.observation`]="{ item }">
            <div class="d-flex align-center justify-center">
              <span>
                {{ item.observation }}
              </span>
              <v-tooltip
                v-if="!item.locked && !item.financialLocked"
                top
              >
                <template #activator="{ on }">
                  <v-btn
                    icon
                    small
                    class="ml-3"
                    v-on="on"
                    @click.stop="showProducerBonusObservationDialog(item)"
                  >
                    <v-icon small>
                      edit
                    </v-icon>
                  </v-btn>
                </template>

                Editar observações
              </v-tooltip>
            </div>
          </template>

          <template #[`item.rangeFinalPrice`]="{ value }">
            {{ value.min.toFixed(4) | Mask(CurrencyMask) }}
            -
            {{ value.max.toFixed(4) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.finalPriceDiff`]="{ item }">
            {{ getRowFinalPriceDiff(item) }}
          </template>

          <template #[`item.total`]="{ item }">
            {{ getRowTotalPrice(item).toFixed(2) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.finalPriceWithoutGovSupport`]="{ item }">
            {{ getRowFinalPriceWithoutGovSupport(item).toFixed(4) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.finalPriceWithoutComplementary`]="{ item }">
            {{ getRowFinalPriceWithoutComplementary(item).toFixed(4) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.lastFinalPriceWithoutComplementary`]="{ value }">
            {{ value.toFixed(4) | Mask(CurrencyMask) }}
          </template>

          <template #[`item.finalPriceWithoutComplementaryDiff`]="{ item }">
            {{ getRowFinalPriceWithoutComplementaryDiff(item) }}
          </template>

          <template #[`item.logs`]="{ item }">
            <v-btn
              icon
              @click="showProducerBonusLog(item)"
            >
              <v-icon>
                info
              </v-icon>
            </v-btn>
          </template>

          <template #[`item.lastFinalPrice`]="{ item }">
            <div class="d-flex align-items-center justify-content-center">
              <div style="flex-grow: 1; text-align: center;">
                {{ parseFloat(item.lastFinalPrice).toFixed(4) | Mask(CurrencyMask) }}
              </div>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon
                    small
                    class="cursor-default mr-3"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                Valor Complem. (Ant.): {{ parseFloat(item.lastComplementaryAmount).toFixed(4) | Mask(CurrencyMask) }}
              </v-tooltip>
            </div>
          </template>
        </data-table>
      </v-card>

      <producers-additionals-adjustment
        ref="producersAdditionalsAdjustment"
        @onAdditionalAdjust="onAdditionalAdjust"
      />

      <producer-final-price-adjustment
        ref="producerFinalPriceAdjustment"
        @onFinalPriceAdjust="onFinalPriceAdjust"
      />

      <producer-bonus-observation-dialog
        ref="producerBonusObservationDialog"
        @save="onProducerBonusObservationSave"
      />

      <import-excel-dialog
        v-model="showImportDialog"
        title="Importar bonificação"
        :fields="importFields"
        @import="onImportBonus"
      />

      <producer-bonus-log-dialog
        ref="producerBonusLogDialog"
      />

      <producers-real-freight-dialog
        ref="producersRealFreightDialog"
        @confirm="onRealFreightConfirm"
      />

      <v-speed-dial
        fixed
        dark
        bottom
        right
        open-on-hover
        direction="top"
        transition="slide-y-reverse-transition"
        class="mr-5"
      >
        <template #activator>
          <v-btn
            color="blue darken-2"
            dark
            large
            fab
          >
            <v-icon>menu</v-icon>
          </v-btn>
        </template>

        <v-btn
          v-if="isAProjection"
          fab
          dark
          color="grey darken-3"
          @click="closeProjection"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                close
              </v-icon>
            </template>

            <span>Fechar projeção</span>
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="hasAdicionalUpdateAccess && !isAProjection && producers.length > 0"
          fab
          dark
          color="orange darken-1"
          :style="isLocked ? getDisabledStyle() : {}"
          @click="!isLocked ? showProducersAdditionalsAdjustimentDialog() : null"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                swap_vert
              </v-icon>
            </template>

            Ajuste Geral de Bonificação
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="hasLockAccess && !isAProjection && producers.length > 0"
          fab
          dark
          :style="(isNextMonthLocked || isComplementaryLocked) ? getDisabledStyle() : {}"
          @click="(!isNextMonthLocked && !isComplementaryLocked) ? saveAndLock() : null"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                {{ isLocked ? 'lock' : 'lock_open' }}
              </v-icon>
            </template>
            <span v-if="isNextMonthLocked">
              Mês {{ nextMonth }} bloqueado
            </span>
            <span v-else-if="isComplementaryLocked">
              Bloqueado para geração de NF-e
            </span>
            <span v-else>
              {{ isLocked ? 'Liberar Edição' : 'Salvar + Bloquear' }}
            </span>
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="hasSaveAccess && !isAProjection && producers.length > 0"
          fab
          dark
          color="blue darken-4"
          :style="isLocked ? getDisabledStyle() : {}"
          @click="!isLocked ? save() : null"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                save
              </v-icon>
            </template>

            <span v-if="isLocked">
              Fechamento bloqueado
            </span>
            <span v-else>
              Salvar
            </span>
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="hasRecountAccess && !isAProjection"
          color="deep-purple lighten-1"
          fab
          dark
          :style="!isDairySelected || isLocked ? getDisabledStyle() : {}"
          @click="isDairySelected && !isLocked ? regenerate() : null"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                attach_money
              </v-icon>
            </template>

            <span v-if="!isDairySelected">
              Laticínio não selecionado
            </span>
            <span v-else-if="isLocked">
              Fechamento bloqueado
            </span>
            <span v-else>
              Recalcular Volume e Qualidade
            </span>
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="producers.length > 0"
          fab
          dark
          color="green darken-1"
          @click="reportToXLS()"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                backup_table
              </v-icon>
            </template>

            Download (Excel)
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="!isAProjection"
          fab
          dark
          color="blue"
          :style="isLocked ? getDisabledStyle() : {}"
          @click="!isLocked ? importBonus() : null"
        >
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon v-on="on">
                system_update_alt
              </v-icon>
            </template>

            Importar
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="hasRecountAccess && !isAProjection && producers.length > 0"
          fab
          dark
          color="light-green darken-4"
          @click="recalculatePrices"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon v-on="on">
                local_atm
              </v-icon>
            </template>

            Recalcular cotação produtor
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="hasLockAccess && rawMaterialType === 'PRINCIPAL' && !isAProjection && producers.length > 0"
          fab
          dark
          color="blue"
          @click="calculateRealFreight"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon v-on="on">
                local_shipping
              </v-icon>
            </template>

            Calcular Preço Frete
          </v-tooltip>
        </v-btn>

        <v-btn
          v-if="rawMaterialType === 'PRINCIPAL' && !isAProjection"
          fab
          dark
          color="grey darken-3"
          :style="!isDairySelected ? { background: 'rgba(255, 255, 255, 0.12) !important', cursor: 'not-allowed' } : {}"
          @click="isDairySelected ? loadProjection() : null"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon v-on="on">
                insights
              </v-icon>
            </template>

            <span v-if="!isDairySelected">
              Laticínio não selecionado
            </span>
            <span v-else>
              Fazer projeção de cotação do produtor
            </span>
          </v-tooltip>
        </v-btn>
      </v-speed-dial>

      <v-overlay :value="loading">
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </div>
  </div>
</template>

<style lang="scss">
.bonus-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.bonus-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }

  .btn-disabled {
    background-color: rgba(255, 255, 255, 0.12) !important;
  }
}
</style>

<script>
import _ from "lodash";
import moment from "moment";
import XLSX from "xlsx-js-style";
import IMask from "imask";

import { sortItems } from 'vuetify/lib/util/helpers';

import MaskedInput from "@/Support/Components/MaskedInput.vue";
import ImportExcelDialog from '@/Support/Components/ImportExcelDialog.vue';
import ReportMixin from "@/Support/Mixins/ReportMixin.js";

import ProducerBonusObservationDialog from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/ProducerBonusObservationDialog.vue";
import ProducerComplementaryMenu from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/ProducerComplementaryMenu.vue";
import ProducersAdditionalsAdjustment from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/ProducersAdditionalsAdjustment.vue";
import ProducerFinalPriceAdjustment from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/ProducerFinalPriceAdjustment.vue";
import ProducerBonusLogDialog from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/ProducerBonusLogDialog.vue";
import ProducersRealFreightDialog from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/ProducersRealFreightDialog.vue";
import BonusProductName from "@/Domains/Producer/Bonus/BonusSpreadsheet/Components/BonusProductName.vue";

import CardInfo from "@/Domains/BI/Components/CardInfo.vue";

export default {

  components: {
    ProducerBonusObservationDialog,
    ProducerComplementaryMenu,
    ProducersAdditionalsAdjustment,
    ProducerFinalPriceAdjustment,
    MaskedInput,
    ImportExcelDialog,
    CardInfo,
    BonusProductName,
    ProducerBonusLogDialog,
    ProducersRealFreightDialog,
  },

  filters: {
    /**
     * Filtro usado em todas as mascaras da view
     */
    Mask(value, mask) {
      if (value == null) {
        value = ''
      }
      return IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },
  },

  mixins: [ReportMixin],

  props: {
    rawMaterialType: {
      type: String,
      default: 'PRINCIPAL',
    }
  },

  data() {
    return {
      rawMaterialId: null,

      // Loaders
      loading: false,

      isAProjection: false,

      isLocked: false,

      isNextMonthLocked: false,

      resumedPriceTable: {},

      // flag para indicar caso for para utilizar o valor do mês passado como cotação base
      wasRecalculatorByLastMonthPrice: false,

      showImportDialog: false,

      // Filtros usados para buscar a rota
      filters: {
        // Dialogs
        datePickerMenu: null,

        // Mês de referência para a bonificação
        date: moment().subtract(1, "month").format("YYYY-MM"),

        // Rota filtrada para busca
        route: [],

        // Lista de rotas disponíveis no formulário
        routes: [],

        // Pessoa filtrada para a busca
        person: {},

        // Tipo de produtor (Todos, Cooperativa, Próprios)
        producerType: '',

        // Lista de pessoas disponíveis no formulário
        persons: [],

        // Campo de busca para buscar pelo nome do produtor
        search: '',

        // Região filtrada para busca
        region: [],

        // Lista de regiões disponíveis no formulário
        regions: [],
      },

      // Lista de produtores para geração da bonificação
      producers: [],

      // Lista de headers de qualidade para serem exibidas na tabela
      additionalHeaders: {},

      // Lista de produtores para importação da bonificação
      persons: [],

      // Rota que está sendo exibida
      selectedRow: [],

      groupBy: undefined,

      groups: {},

      hasProducersInCoop: false,

      // Mascara usada nos valores adicionáis do leite
      MilkCurrencyMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            signed: true,
          }
        }
      },

      AdditionalMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 6,
            signed: true,
          }
        }
      },

      // Mascara usada nos valores em R$
      CurrencyMask: {
        mask: 'R$ num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: '.',
            scale: 4,
            signed: true,
          }
        }
      },

      // Mascara usada nos damais campos numéricos
      NumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: '.',
            signed: true,
          }
        }
      },

      // Média pondera do último valor final
      lastMilkPriceAvg: 0,
      lastTotalVol: 0,
      lastPlatformCostAvg: 0,
    };
  },

  computed: {

    /**
     * Recupera o mês atual para definir o período máximo disponível para filtro
     * @returns {string}
     */
    maxDateRef() {
      return moment().format("YYYY-MM");
    },

    /**
     * Mês de referência definido nos filtros
     * @returns {string|null}
     */
    dateRefFilter() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, "YYYY-MM").format("MM/YYYY");
    },

    nextMonth() {
      if (_.isEmpty(this.filters.date)) {
        return null;
      }

      return moment(this.filters.date, 'YYYY-MM').add(1, 'month').format('MM/YYYY');
    },

    /**
     * Lista filtrada de produtores
     * @returns {Array}
     */
    filteredBonusList() {
      const { search } = this.filters;

      if (_.isEmpty(search)) {
        return this.producers;
      }

      const searchUpper = search ? search.toUpperCase() : '';

      return this.producers.filter(({ producer }) => {
        const matchesSearch = searchUpper ? producer.search.toUpperCase().includes(searchUpper) : true;

        return matchesSearch;
      });
    },

    hasPendingChanges() {
      return this.producers.some(producer =>
        producer.dairyAdditionalAmountChanged ||
        producer.technicalAdditionalAmountChanged ||
        producer.complementaryAmountChanged ||
        producer.realFreightPriceChanged ||
        producer.observationChanged
      );
    },

    /**
     * Recupera o role do usuário
     * @returns {String}
     */

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasLockAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bonus-lock" && o.tipo === "COMPONENTE");
    },

    hasSaveAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bonus-save" && o.tipo === "COMPONENTE");
    },

    hasRecountAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bonus-recount" && o.tipo === "COMPONENTE");
    },

    hasEditingPrice() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bonus-editing-price" && o.tipo === "COMPONENTE");
    },

    hasEditingPriceTechnician() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bonus-editing-price-techinican" && o.tipo === "COMPONENTE");
    },
    hasAdicionalUpdateAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bonificacao-ajustes-gerais" && o.tipo === "COMPONENTE");
    },
    hasAccessEditingQuoteChanges() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "bonus-access-editing-quote-changes" && o.tipo === "COMPONENTE");
    },

    /**
     * Verifica se todos os registros da lista estão bloqueados
     * Usado no icone do botão que bloqueia os registros
     * @returns {Boolean}
     */
    isFinancialLocked() {
      return this.producers.length > 0 && this.producers.filter(producer => producer.financialLocked).length === this.producers.length;
    },

    /**
     * Verifica se todos os registros da lista estão bloqueados
     * Usado no icone do botão que bloqueia os registros
     * @returns {Boolean}
     */
    isComplementaryLocked() {
      return this.producers.length > 0 && this.producers.filter(producer => producer.complementaryLocked).length === this.producers.length;
    },

    /**
     * Verifica se existem registros disponíveis para salvar
     * @returns {Boolean}
     */
    hasRowsToSave() {
      return this.producers.filter(producer => {
        if (this.isAdmin) {
          return true;
        }

        // Se não for admin, retorna apenas as que podem ser alteradas
        return !producer.locked && !producer.financialLocked && !this.isFinalPriceOutOfRange(producer);
      }).length > 0
    },

    /**
     * Recupera o valor base do litro do leite
     * @returns {Number}
     */
    baseValue() {
      const firstRow = _.first(this.producers);

      if (!firstRow) {
        return 0.0;
      }

      return firstRow.baseAmount;
    },

    /**
     * Recupera o volume total de leite
     * @returns {Number}
     */
    totalVol() {
      if (_.isEmpty(this.producers)) {
        return 0.0;
      }

      return _.sumBy(this.producers, 'vol');
    },

    /**
     * Recupera o preço médio do litro do leite
     * @returns {Number}
     */
    milkPriceAvg() {
      if (_.isEmpty(this.producers)) {
        return 0.0;
      }

      if (this.priceAvgByNetPrice) {
        const totalNetPrice = this.producers.reduce((acc, item) => acc + (this.getNetPrice(item) * item.vol), 0);

        return totalNetPrice / this.totalVol;
      }

      return this.totalPaidAmount / this.totalVol;
    },

    /**
     * Recupera o valor total a pagar
     * @returns {Number}
     */
    totalPaidAmount() {
      if (_.isEmpty(this.producers)) {
        return 0.0;
      }

      return _.sumBy(this.producers, (o) => this.getRowTotalPrice(o));
    },

    milkPriceAvgDifference() {
      return this.milkPriceAvg - this.lastMilkPriceAvg;
    },

    totalVolDifference() {
      return this.totalVol - this.lastTotalVol;
    },

    hasPlatformCost() {
      return this.producers.some(item => item.realFreightPrice > 0);
    },

    platformCost() {
      return this.producers.reduce((acc, item) => acc + this.getPlatformTotalPrice(item), 0);
    },

    platformCostAvg() {
      const totalPlatformCost = this.producers.reduce((acc, item) => acc + this.getPlatformTotalPrice(item), 0);

      return (totalPlatformCost / this.totalVol) || 0.0;
    },

    platformCostAvgDifference() {
      return this.platformCostAvg - this.lastPlatformCostAvg;
    },

    groupTotals() {
      if (!this.groupBy) {
        return {};
      }

      const groups = _.groupBy(this.producers.filter(p => p.producer.group), 'producer.group');

      return _.mapValues(groups, (values) => ({
        total: _.sumBy(values, (o) => this.getRowTotalPrice(o)).toFixed(2),
        finalPrice: this.getValueIfAllEqual(values.map(item =>  this.getRowFinalPrice(item)))?.toFixed(4),
        finalPriceDiff: this.getValueIfAllEqual(values.map(item =>  this.getRowFinalPriceDiff(item))),
        complementaryAmount: this.getValueIfAllEqual(values.map(item =>  item.complementaryAmount.total))?.toFixed(4),
      }));
    },

    settings() {
      return this.$store.state.settings.gerais.taxas || {};
    },

    priceAvgByNetPrice() {
      return this.$store.state.settings?.gerais?.preco_medio_produtor_liquido || false;
    },

    importFields() {
      /**
       * Obtém as colunas da última importação,
       * desse modo, seleciona automaticamente as colunas
       */
      const settings = this.$store.state.settings.gerais.campos_importados_bonificacao || {};
      return [
        { text: 'Código do Produtor', keys: ['Código do Produtor', settings['Código do Produtor'] ].filter(o => o), required: true },

        // Bonificação Qualidade
        { text: 'Valor CCS', keys: ['Valor CCS', settings['Valor CCS'] ].filter(o => o) },
        { text: 'Valor CPP', keys: ['Valor CPP', settings['Valor CPP'] ].filter(o => o) },
        { text: 'Valor ESD', keys: ['Valor ESD', settings['Valor ESD'] ].filter(o => o) },
        { text: 'Valor Proteína', keys: ['Valor Proteína', settings['Valor Proteína'] ].filter(o => o) },
        { text: 'Valor Gordura', keys: ['Valor Gordura', settings['Valor Gordura'] ].filter(o => o) },
        { text: 'Valor Caseína', keys: ['Valor Caseína', settings['Valor Caseína'] ].filter(o => o) },
        { text: 'Valor Ureia', keys: ['Valor Ureia', settings['Valor Ureia'] ].filter(o => o) },
        { text: 'Valor Sólidos Totais', keys: ['Valor Sólidos Totais', settings['Valor Sólidos Totais'] ].filter(o => o) },
        { text: 'Valor Lactose', keys: ['Valor Lactose', settings['Valor Lactose'] ].filter(o => o) },
        { text: 'Valor CCS TRIMESTRAL', keys: ['Valor CCS TRIMESTRAL', settings['Valor CCS TRIMESTRAL'] ].filter(o => o) },
        { text: 'Valor CPP TRIMESTRAL', keys: ['Valor CPP TRIMESTRAL', settings['Valor CPP TRIMESTRAL'] ].filter(o => o) },

        // Bonificação Outros
        { text: 'Valor Volume', keys: ['Valor Volume', settings['Valor Volume'] ].filter(o => o) },
        { text: 'Valor Distância', keys: ['Valor Distância', settings['Valor Distância'] ].filter(o => o) },
        { text: 'Valor BPA', keys: ['Valor BPA', settings['Valor BPA'] ].filter(o => o) },
        { text: 'Valor Fidelidade', keys: ['Valor Fidelidade', settings['Valor Fidelidade'] ].filter(o => o) },
        { text: 'Valor Brucelose', keys: ['Valor Brucelose', settings['Valor Brucelose'] ].filter(o => o) },
        { text: 'Valor Tuberculose', keys: ['Valor Tuberculose', settings['Valor Tuberculose'] ].filter(o => o) },

        // Adicionais extras
        { text: 'Adc. Mercado', keys: ['Adc. Mercado', settings['Adc. Mercado'] ].filter(o => o) },
        { text: 'Adc. Técnico',  keys: ['Adc. Técnico', settings['Adc. Técnico'] ].filter(o => o) },

        // Valor final da cotação
        { text: 'Cotação Paga', keys: ['Cotação Paga', settings['Cotação Paga'] ].filter(o => o) },
        { text: 'Volume', keys: ['Volume', settings['Volume'] ].filter(o => o) },

        { text: 'Nota Fiscal', keys: ['Nota Fiscal', settings['Nota Fiscal'] ].filter(o => o) },
      ]
    },

    isDairySelected() {
      return !_.isEmpty(this.$store.state.settings.laticinio)
    },

    headers() {
      const quality = _.get(this.additionalHeaders, 'quality');
      const others = _.get(this.additionalHeaders, 'others');

      const qualityHeaders = _.map(quality, (additional) => {
        return [
          {
            text: `Resultado ${additional.label}`,
            value: `result.quality.${additional.field}.result`,
            align: 'center',
            default: false,
            show: false,
          },
          {
            text: `Valor ${additional.label}`,
            value: `result.quality.${additional.field}.amount`,
            align: 'center',
            default: false,
            show: false,
            formatter: value => this.formatCurrency(value)
          }
        ];
      });

      const othersHeaders = _.map(others, (otherAdditional) => {
        const headers = [
          {
            text: `Valor ${otherAdditional.label}`,
            value: `result.others.${otherAdditional.field}.amount`,
            align: 'center',
            default: false,
            show: false,
            formatter: value => this.formatCurrency(value)
          }
        ];

        if (otherAdditional.type !== 'extra') {
          return [
            {
              text: `Resultado ${otherAdditional.label}`,
              value: `result.others.${otherAdditional.field}.result`,
              default: false,
              show: false,
            },
            ...headers,
          ];
        }

        return headers;
      });

      if (this.groupBy) {
        othersHeaders.push({
          text: 'Grupo',
          value: 'producer.group',
          default: false,
          show: false,
        })
      }

      return [
        {
          align: "center",
          text: "Código do Produtor",
          value: "producer.code",
          default: true,
          show: true,
        },
        {
          align: "left",
          text: "Produtor",
          value: "producer.name",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Município",
          value: "producer.city",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Rota",
          value: "producer.route",
          default: true,
          show: false,
        },
        {
          align: "center",
          text: "Região",
          value: "producer.region",
          default: false,
          show: false,
        },
        {
          align: "center",
          text: "Cooperativa",
          value: "producer.cooperative.name",
          default: false,
          show: false,
        },
        ..._.flatten(qualityHeaders),
        {
          align: "center",
          text: "Qualidade",
          value: "result.totals.quality",
          default: true,
          show: true,
        },
        ..._.flatten(othersHeaders),
        {
          align: "center",
          text: "Outros",
          value: "result.totals.others",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Adc. Mercado",
          value: "dairyAdditionalAmount",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Adc. Técnico",
          value: "technicalAdditionalAmount",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Valor Complem.",
          value: "complementaryAmount",
          default: true,
          show: true,
        },
        {
          text: `Cotação s/ Incentivo`,
          value: `finalPriceWithoutGovSupport`,
          width: 160,
          align: 'center',
          default: false,
          show: false,
        },
        {
          align: "center",
          text: "Perc. Incentivo",
          value: "govSupportPerc",
          default: false,
          show: false,
        },
        {
          align: "center",
          text: "Valor Incentivo",
          value: "govSupportPrice",
          default: false,
          show: false,
          sortable: false,
        },
        {
          align: "center",
          text: "Valor Frete Nota",
          value: "freightAmount",
          default: false,
          show: false,
        },
        {
          align: "center",
          text: "Preço Frete Nota",
          value: "invoiceFreightPrice",
          default: false,
          show: false,
        },
        {
          align: "center",
          text: "Cotação Líquida (Ant.)",
          value: "lastNetPrice",
          default: false,
          show: false,
          sortable: false,
        },
        {
          align: "center",
          text: "Cotação Líquida",
          value: "netPrice",
          default: true,
          show: true,
          sortable: false,
        },
        {
          align: "center",
          text: "Cotação Paga (Ant.)",
          value: "lastFinalPrice",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Cotação Paga",
          value: "finalPrice",
          default: true,
          show: true,
          sort: (a, b) => this.getRowFinalPrice(a, true) - this.getRowFinalPrice(b, true)
        },
        {
          align: "center",
          text: "Cotação Min. e Máx.",
          value: "rangeFinalPrice",
          default: true,
          show: true,
          sortable: false,
        },
        {
          align: "center",
          text: "R$ Diferença",
          value: "finalPriceDiff",
          default: true,
          show: true,
          sort: (a, b) => this.getRowFinalPriceDiff(a, true) - this.getRowFinalPriceDiff(b, true)
        },
        {
          align: "center",
          text: "Cotação s/ Compl. (Ant.)",
          value: "lastFinalPriceWithoutComplementary",
          default: false,
          show: false,
        },
        {
          align: "center",
          text: "Cotação s/ Compl.",
          value: "finalPriceWithoutComplementary",
          default: false,
          show: false,
          sort: (a, b) => this.getRowFinalPriceWithoutComplementary(a, true) - this.getRowFinalPriceWithoutComplementary(b, true),
        },
        {
          align: "center",
          text: "Diferença (Cotação s/ Compl.)",
          value: "finalPriceWithoutComplementaryDiff",
          default: false,
          show: false,
          sort: (a, b) => this.getRowFinalPriceWithoutComplementaryDiff(a, true) - this.getRowFinalPriceWithoutComplementaryDiff(b, true)
        },
        {
          align: "center",
          text: "Volume (Ant.)",
          value: "lastVol",
          default: true,
          show: false,
        },
        {
          align: "center",
          text: "Volume",
          value: "vol",
          default: true,
          show: true,
        },
        {
          align: "center",
          text: "Vol. Diferença",
          value: "volDiff",
          default: true,
          show: false,
        },
        {
          align: "center",
          text: "Valor Entrega",
          value: "total",
          default: true,
          show: true,
          sortable: false,
        },
        {
          align: "center",
          text: "Nota Fiscal",
          value: "invoiceNumber",
          default: true,
          show: false,
        },
        {
          align: "center",
          text: "Preço Frete",
          value: "realFreightPrice",
          default: false,
          show: false,
          formatter: value => this.formatCurrency(value),
        },
        {
          align: "center",
          text: "Preço Plataforma",
          value: "platformPrice",
          default: false,
          show: false,
          formatter: value => this.formatCurrency(value),
        },
        {
          align: "center",
          text: "Valor Total Plataforma",
          value: "platformTotalPrice",
          default: false,
          show: false,
          formatter: value => this.formatCurrency(value),
        },
        {
          align: "center",
          text: "Logs",
          value: "logs",
          default: true,
          show: false,
        },
        {
          align: "left",
          text: "Observações",
          value: "observation",
          default: true,
          show: false,
        },
      ];
    },

  },

  // eslint-disable-next-line
  async beforeRouteLeave(to, from, next) {
    if (this.hasPendingChanges) {
      if (!(await this.$root.$confirm('Alterações pendentes não salvas', '', { color: 'orange', token: 'SAIR' }))
      ) {
        return;
      }
    }
    next();
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
  },

  async mounted() {
    const settings = this.$store.state.settings?.gerais || {};
    this.rawMaterialId = this.rawMaterialType === 'PRINCIPAL' ? settings?.id_materia_prima_principal : settings?.id_materia_prima_secundaria;

    this.loadFilters();

    await this.loadMaxPriceByRoute();

    await this.loadBonusList();

    this.autoFocusFirst();

    this.loadProducers();
  },

  methods: {
    preventNav(event) {
      if (!this.hasPendingChanges) return
      event.preventDefault()
      event.returnValue = ''
    },

    /**
     * Recupera a lista de bonificações
     * @returns {Promise<void>}
     */
    async loadBonusList() {
      try {
        this.isAProjection = false;
        this.loading = true;

        const { data } = await this.$axios.post(
          `/tabelaPreco/extratoMensalProdutores`,
          {
            mes_referencia: this.filters.date,
            tipo: this.rawMaterialType,
            id_materia_prima: this.rawMaterialId,
            id_rota: this.filters.route,
            id_usuario: this.filters.person,
            id_regiao: this.filters.region,
            tipo_produtor: this.filters.producerType,
          }
        );

        if (!_.isObject(data)) {
          throw "PHP Error";
        }
        const { producers, additionalHeaders } = this.parseData(data);

        this.producers = producers;
        this.additionalHeaders = additionalHeaders;

        this.isLocked = this.producers.length > 0 && !this.producers.some(producer => !producer.locked);

        await this.loadNextMonthStatus();

        await this.loadLastFinalPriceAvg();

        if (this.isComplementaryLocked) {
          this.isLocked = true;
        }

        // Se na primeria consulta possuir cooperativa já exibe o select, não remover o if para não sumir o select
        if (data.some(producer => producer.id_cooperativa)) {
          this.hasProducersInCoop = true;
        }

        const groups = _.groupBy(producers.filter(p => p.producer.group), 'producer.group');
        const hasGroups = Object.values(groups).some(group => group.length > 1);

        this.groups = _.mapValues(groups, (values) => ({
          dairyAdditionalAmount: this.getValueIfAllEqual(values, 'dairyAdditionalAmount'),
          technicalAdditionalAmount: this.getValueIfAllEqual(values, 'technicalAdditionalAmount'),
          locked: values.some(p => p.locked),
          financialLocked: values.some(p => p.financialLocked),
          rangeFinalPrice: this.getValueIfAllEqual(values, 'rangeFinalPrice'),
          city: this.getValueIfAllEqual(values, 'producer.city'),
          vol: _.sumBy(values, 'vol').toFixed(0),
          lastVol: _.sumBy(values, 'lastVol').toFixed(0),
          volDiff: _.sumBy(values, 'volDiff').toFixed(0),
          lastFinalPrice: this.getValueIfAllEqual(values, 'lastFinalPrice')?.toFixed(4),
          quality: this.getValueIfAllEqual(values, 'result.totals.quality')?.toFixed(4),
          others: this.getValueIfAllEqual(values, 'result.totals.others')?.toFixed(4),
        }));

        this.groupBy = hasGroups ? 'producer.group' : undefined

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    parseData(data) {
      let additionalHeaders = {};
      const producers = data.map((producer, index) => {
        const qualityAdditionals = producer.adicionais_qualidade.map(additional => {
          return {
            field: additional.campo,
            label: additional.label,
            result: additional.resultado,
            amount: additional.valor,
          }
        });

        const othersAdditionals = producer.outros_adicionais.map(additional => {
          return {
            field: additional.campo,
            label: additional.label,
            result: additional.resultado,
            amount: additional.valor,
            type: additional.tipo,
          }
        });

        const qualityHeaders = qualityAdditionals.reduce((headers, additional) => {
          headers[additional.field] = _.omit(additional, ['result', 'amount']);

          return headers;
        }, {});

        const othersHeaders = othersAdditionals.reduce((headers, additional) => {
          headers[additional.field] = _.omit(additional, ['result', 'amount']);

          return headers;
        }, {});

        additionalHeaders = {
          quality: {
            ...additionalHeaders.quality,
            ...qualityHeaders,
          },
          others: {
            ...additionalHeaders.others,
            ...othersHeaders,
          }
        };

        return {
          index,
          id: producer.id_formacao_valor_leite,
          baseAmount: parseFloat(producer.valor_base),
          vol: parseFloat(producer.volume_resultado) || 0,
          producer: {
            id: producer.id_produtor,
            code: producer.codigo_laticinio,
            name: producer.nome_produtor,
            status: producer.status_produtor,
            route: producer.nome_rota,
            routeId: producer.id_rota,
            city: producer.end_cidade,
            region: producer.nome_regiao,
            group: producer.nome_condominio,
            cooperative: {
              id: producer.id_cooperativa,
              name: producer.nome_cooperativa,
            },
            search: `${producer.codigo_laticinio || ''} - ${producer.nome_produtor} - ${producer.nome_condominio || ''}`.toUpperCase(),
          },
          result: {
            quality: _.keyBy(qualityAdditionals, 'field'),
            others: _.keyBy(othersAdditionals, 'field'),
            totals: {
              quality: parseFloat(producer.valor_bonificacao_qualidade) || 0,
              others: parseFloat(producer.valor_bonificacao_adicional) || 0,
            },
          },
          dairyAdditionalAmount: parseFloat(producer.valor_adicional1) || 0.0,
          technicalAdditionalAmount: parseFloat(producer.valor_adicional2) || 0.0,
          complementaryAmount: {
            total: parseFloat(producer.valor_complementar) || 0.0,
            items: JSON.parse(producer.complementos || '[]').map(item => ({
              amount: item.valor,
              invoice: item.numero_nfe,
              id: item.id_complemento,
            })),
            show: false,
          },
          lastComplementaryAmount: parseFloat(producer.valor_complementar_anterior) || 0.0,
          lastFinalPrice: parseFloat(producer.valor_unitario_final_anterior) || 0.0,
          lastFinalPriceWithoutComplementary: parseFloat(producer.valor_unitario_final_anterior) - parseFloat(producer.valor_complementar_anterior),
          finalPriceWithoutComplementary: index, // Usado para obter o item pelo índice na ordenação
          finalPriceWithoutComplementaryDiff: index, // Usado para obter o item pelo índice na ordenação
          govSupportPerc: parseFloat(producer.percentual_despesas) || 0.0,
          rangeFinalPrice: this.getRangeFinalPrice(producer.id_rota),
          finalPriceDiff: index, // Usado para obter o item pelo índice na ordenação
          lastVol: parseFloat(producer.volume_resultado_anterior) || 0,
          volDiff: (parseFloat(producer.volume_resultado) || 0) - (parseFloat(producer.volume_resultado_anterior) || 0),
          qualityAmount: parseFloat(producer.valor_qualidade) || 0.0,
          taxAmount: parseFloat(producer.valor_taxas) || 0.0,
          freightAmount: parseFloat(producer.valor_frete) || 0.0,
          invoiceFreightPrice: producer.valor_frete ? (parseFloat(producer.valor_frete) / parseFloat(producer.volume_resultado)) : 0.0,
          lastNetPrice: producer.cotacao_liquida_anterior,
          invoiceNumber: producer.numero_nfe,
          realFreightPrice: parseFloat(producer.valor_frete_real) || 0,
          observation: producer.observacao,
          locked: producer.status === 1,
          originalLocked: producer.status === 1,
          financialLocked: producer.status_financeiro === 'CONCLUIDO' || !!producer.numero_nfe,
          complementaryLocked: producer.status_financeiro === 'CONCLUIDO',
          finalPrice: index // Usado para obter o item pelo índice na ordenação
        };
      });

      return {
        producers,
        additionalHeaders,
      }
    },

    async loadNextMonthStatus() {
      const { data } = await this.$axios.post(
        `/tabelaPreco/statusBonificacao`,
        {
          mes_referencia: moment(this.filters.date, 'YYYY-MM').add(1, 'month').format('YYYY-MM'),
          tipo: this.rawMaterialType,
          id_materia_prima: this.rawMaterialId,
        }
      );

      this.isNextMonthLocked = data.bloqueado;

      if (this.isNextMonthLocked) {
        this.isLocked = true;
      }
    },

    /**
     * Retorna uma tabela de projeção
     * @returns {Promise<void>}
     */
    async loadProjection() {
      if (this.rawMaterialId) {
        return;
      }
      try {
        this.isAProjection = true;
        this.loading = true;

        const { data } = await this.$axios.get(`/bonus/statement/projection`, { params: {
          month_reference: this.filters.date,
          routes: this.filters.route,
          user_id: this.filters.person,
          region: this.filters.region,
        } });

        const { producers, additionalHeaders } = this.parseData(data);

        this.producers = producers;
        this.additionalHeaders = additionalHeaders;
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    closeProjection() {
      this.isAProjection = false;
      this.loadBonusList();
    },

    async loadMaxPriceByRoute() {
      if (this.rawMaterialType !== 'PRINCIPAL') {
        return;
      }
      try {
        const { data } = await this.$axios.get(`/bonus/price-table/resumed`);

        this.resumedPriceTable = data.reduce((acc, cur) => ({
          ...acc,
          [cur.id_rota]: { min: cur.valor_minimo, max: cur.valor_maximo }
        }), {});
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a tabela de preços!", "Atenção");

        console.log(err);
      }
    },

    getRangeFinalPrice(routeId) {
      if (_.has(this.resumedPriceTable, routeId)) {
        return this.resumedPriceTable[routeId];
      }

      return this.resumedPriceTable[null] || { min: 0, max: 0 };
    },

    isFinalPriceOutOfRange(item) {
      const finalPrice = this.getRowFinalPrice(item);

      return finalPrice < item.rangeFinalPrice.min || finalPrice > item.rangeFinalPrice.max;
    },

    async importBonus() {
      this.showImportDialog = true;
    },

    /**
     * Salva os nomes das colunas importadas nas configurações,
     * desse modo nas próximas importações as colunas serão selecionadas automaticamente
     */
    async saveLastImportedFields(fields) {
      try {
        let { data } = await this.$axios.post(
          `/configuracao/salvaConfiguracaoAppQualidade`,
          this.$qs.stringify({
            configuracaoQualidade: JSON.stringify([{
              campos_importados_bonificacao: fields.reduce((acc, cur) => ({ ...acc, [cur.text]: cur.value }), {})
            }]),
          })
        );

        [data] = data;

        if (data.codigo !== 1) {
          throw data;
        }

        this.$store.dispatch('updateStoreSetting');
      } catch (error) {
        this.$snotify.error("Erro ao salvar as configurações", "Atenção");
        console.error(error);
      }
    },

    async onImportBonus({ data, fields }) {
      this.showImportDialog = false;
      try {
        this.loading = true;

        this.saveLastImportedFields(fields);

        let failed = [];

        data.forEach(item => {
          const index = this.producers.findIndex(({ producer }) => producer.code === item['Código do Produtor']);

          let baseAmount = this.baseValue;
          let vol = parseFloat(item['Volume']) || 0;
          const invoiceNumber = item['Nota Fiscal'];

          // Bonificação Qualidade
          let amountCCS = parseFloat(item['Valor CCS']) || 0;
          let amountCBT = parseFloat(item['Valor CPP']) || 0;
          let amountESD = parseFloat(item['Valor ESD']) || 0;
          let amountProtein = parseFloat(item['Valor Proteína']) || 0;
          let amountFat = parseFloat(item['Valor Gordura']) || 0;
          let amountCasein = parseFloat(item['Valor Caseína']) || 0;
          let amountUrea = parseFloat(item['Valor Ureia']) || 0;
          let amountTotalSolids = parseFloat(item['Valor Sólidos Totais']) || 0;
          let amountLactose = parseFloat(item['Valor Lactose']) || 0;
          let amountCcsQuarterly = parseFloat(item['Valor CCS TRIMESTRAL']) || 0;
          let amountCppQuarterly = parseFloat(item['Valor CPP TRIMESTRAL']) || 0;

          // Bonificação Outros
          let amountVol = parseFloat(item['Valor Volume']) || 0;
          let amountDistance = parseFloat(item['Valor Distância']) || 0;
          let amountBPA = parseFloat(item['Valor BPA']) || 0;
          let amountLoyalty = parseFloat(item['Valor Fidelidade']) || 0;
          let amountBrucelose = parseFloat(item['Valor Brucelose']) || 0;
          let amountTuberculose = parseFloat(item['Valor Tuberculose']) || 0;

          if (index > -1) {
            const bonus = this.producers[index];

            baseAmount = bonus.baseAmount;

            if (item['Volume'] === undefined) {
              vol = bonus.vol;
            }

            // Bonificação Qualidade
            if (item['Valor CCS'] === undefined) {
              amountCCS = _.get(bonus, 'result.quality.ccs.amount', 0);
            }
            if (item['Valor CPP'] === undefined) {
              amountCBT = _.get(bonus, 'result.quality.cpp.amount', 0);
            }
            if (item['Valor ESD'] === undefined) {
              amountESD = _.get(bonus, 'result.quality.esd.amount', 0);
            }
            if (item['Valor Proteína'] === undefined) {
              amountProtein = _.get(bonus, 'result.quality.proteina.amount', 0);
            }
            if (item['Valor Gordura'] === undefined) {
              amountFat = _.get(bonus, 'result.quality.gordura.amount', 0);
            }
            if (item['Valor Caseína'] === undefined) {
              amountCasein = _.get(bonus, 'result.quality.caseina.amount', 0);
            }
            if (item['Valor Ureia'] === undefined) {
              amountUrea = _.get(bonus, 'result.quality.ureia.amount', 0);
            }
            if (item['Valor Sólidos Totais'] === undefined) {
              amountTotalSolids = _.get(bonus, 'result.quality.solidosTotais.amount', 0);
            }
            if (item['Valor Lactose'] === undefined) {
              amountLactose = _.get(bonus, 'result.quality.lactose.amount', 0);
            }
            if (item['Valor CCS TRIMESTRAL'] === undefined) {
              amountCcsQuarterly = _.get(bonus, 'result.quality.ccsTrimestral.amount', 0);
            }
            if (item['Valor CPP TRIMESTRAL'] === undefined) {
              amountCppQuarterly = _.get(bonus, 'result.quality.cppTrimestral.amount', 0);
            }

            // Bonificação Outros
            if (item['Valor Volume'] === undefined) {
              amountVol = _.get(bonus, 'result.others.volume.amount', 0);
            }
            if (item['Valor Distância'] === undefined) {
              amountDistance = _.get(bonus, 'result.others.distancia.amount', 0);
            }
            if (item['Valor BPA'] === undefined) {
              amountBPA = _.get(bonus, 'result.others.bpa.amount', 0);
            }
            if (item['Valor Fidelidade'] === undefined) {
              amountLoyalty = _.get(bonus, 'result.others.fidelidade.amount', 0);
            }
            if (item['Valor Brucelose'] === undefined) {
              amountBrucelose = _.get(bonus, 'result.others.brucelose.amount', 0);
            }
            if (item['Valor Tuberculose'] === undefined) {
              amountTuberculose = _.get(bonus, 'result.others.tuberculose.amount', 0);
            }
          }

          // Totais de bonificação
          const totalQuality = +((amountCCS + amountCBT + amountESD + amountProtein + amountFat + amountCasein + amountUrea + amountTotalSolids + amountLactose + amountCcsQuarterly + amountCppQuarterly).toFixed(4));
          const totalOthers = +((amountVol + amountDistance + amountBPA + amountLoyalty + amountBrucelose + amountTuberculose).toFixed(4));

          const technicalAdditionalAmount = parseFloat(item['Adc. Técnico']) || 0;
          let dairyAdditionalAmount = parseFloat(item['Adc. Mercado']) || 0;

          let finalPrice = parseFloat(item['Cotação Paga']) || 0;

          if (finalPrice) {
            dairyAdditionalAmount = +((finalPrice - baseAmount - technicalAdditionalAmount - totalQuality - totalOthers).toFixed(4));
          }
          else {
            finalPrice = +((baseAmount + technicalAdditionalAmount + dairyAdditionalAmount + totalQuality + totalOthers).toFixed(4));
          }

          const qualityAmount = baseAmount + totalQuality;

          if (!finalPrice) {
            failed.push(`[Código: ${item['Código do Produtor']} - Motivo: Dados inválidos]`);
            return;
          }

          if (index > -1) {
            const bonus = this.producers[index];

            if (bonus.locked || bonus.financialLocked) {
              failed.push(`[Código: ${item['Código do Produtor']} - Motivo: Sem permissão]`);
              return;
            }

            this.producers.splice(index, 1, ({
              index,
              id: bonus.id,
              baseAmount,
              vol,
              producer: bonus.producer,
              result: {
                quality: {
                  ccs: {
                    label: "CCS",
                    result: _.get(bonus, 'result.quality.ccs.result', 0),
                    amount: amountCCS,
                  },
                  cpp: {
                    label: "CPP",
                    result: _.get(bonus, 'result.quality.cpp.result', 0),
                    amount: amountCBT,
                  },
                  esd: {
                    label: "ESD",
                    result: _.get(bonus, 'result.quality.esd.result', 0),
                    amount: amountESD,
                  },
                  proteina: {
                    label: "Proteína",
                    result: _.get(bonus, 'result.quality.proteina.result', 0),
                    amount: amountProtein,
                  },
                  gordura: {
                    label: "Gordura",
                    result: _.get(bonus, 'result.quality.gordura.result', 0),
                    amount: amountFat,
                  },
                  caseina: {
                    label: "Caseína",
                    result: _.get(bonus, 'result.quality.caseina.result', 0),
                    amount: amountCasein,
                  },
                  ureia: {
                    label: "Ureia",
                    result: _.get(bonus, 'result.quality.ureia.result', 0),
                    amount: amountUrea,
                  },
                  solidosTotais: {
                    label: "Sólidos Totais",
                    result: _.get(bonus, 'result.quality.solidosTotais.result', 0),
                    amount: amountTotalSolids,
                  },
                  lactose: {
                    label: "Lactose",
                    result: _.get(bonus, 'result.quality.lactose.result', 0),
                    amount: amountLactose,
                  },
                  ccsTrimestral: {
                    label: "CCS TRIMESTRAL",
                    result: _.get(bonus, 'result.quality.ccsTrimestral.result', 0),
                    amount: amountCcsQuarterly,
                  },
                  cppTrimestral: {
                    label: "CPP TRIMESTRAL",
                    result: _.get(bonus, 'result.quality.cppTrimestral.result', 0),
                    amount: amountCppQuarterly,
                  },
                },
                others: {
                  volume: {
                    label: "Volume",
                    result: vol,
                    amount: amountVol,
                  },
                  distancia: {
                    label: "Distância",
                    result: _.get(bonus, 'result.others.distancia.result', 0),
                    amount: amountDistance,
                  },
                  bpa: {
                    label: "BPA",
                    result: _.get(bonus, 'result.others.bpa.result', 0),
                    amount: amountBPA,
                  },
                  fidelidade: {
                    label: "Fidelidade",
                    result: _.get(bonus, 'result.others.fidelidade.result', 0),
                    amount: amountLoyalty,
                  },
                  brucelose: {
                    label: "Brucelose",
                    result: _.get(bonus, 'result.others.brucelose.result', 0),
                    amount: amountBrucelose,
                  },
                  tuberculose: {
                    label: "Tuberculose",
                    result: _.get(bonus, 'result.others.tuberculose.result', 0),
                    amount: amountTuberculose,
                  },
                },
                totals: {
                  quality: totalQuality,
                  others: totalOthers,
                },
              },
              dairyAdditionalAmount,
              technicalAdditionalAmount,
              complementaryAmount: {
                total: 0.0,
                items: [],
                show: false,
              },
              lastFinalPrice: bonus.lastFinalPrice,
              rangeFinalPrice: this.getRangeFinalPrice(bonus.producer.routeId),
              lastVol: bonus.lastVol,
              qualityAmount,
              locked: bonus.locked,
              invoiceNumber,
              imported: true,
            }));
          }
          else {
            const producer = this.persons.find((producer) => producer.code === item['Código do Produtor']);

            if (!producer) {
              failed.push(`[Código: ${item['Código do Produtor']} - Motivo: Produtor não encontrado]`);
              return;
            }

            this.producers.push({
              index: this.producers.length,
              id: null,
              baseAmount,
              vol,
              producer: producer,
              result: {
                quality: {
                  ccs: {
                    label: "CCS",
                    result: 0,
                    amount: amountCCS,
                  },
                  cpp: {
                    label: "CPP",
                    result: 0,
                    amount: amountCBT,
                  },
                  esd: {
                    label: "ESD",
                    result: 0,
                    amount: amountESD,
                  },
                  proteina: {
                    label: "Proteína",
                    result: 0,
                    amount: amountProtein,
                  },
                  gordura: {
                    label: "Gordura",
                    result: 0,
                    amount: amountFat,
                  },
                  caseina: {
                    label: "Caseína",
                    result: 0,
                    amount: amountCasein,
                  },
                  ureia: {
                    label: "Ureia",
                    result: 0,
                    amount: amountUrea,
                  },
                  solidosTotais: {
                    label: "Sólidos Totais",
                    result: 0,
                    amount: amountTotalSolids,
                  },
                  lactose: {
                    label: "Lactose",
                    result: 0,
                    amount: amountLactose,
                  },
                  ccsTrimestral: {
                    label: "CCS TRIMESTRAL",
                    result: 0,
                    amount: amountCcsQuarterly,
                  },
                  cppTrimestral: {
                    label: "CPP TRIMESTRAL",
                    result: 0,
                    amount: amountCppQuarterly,
                  },
                },
                others: {
                  volume: {
                    label: "Volume",
                    result: vol,
                    amount: amountVol,
                  },
                  distancia: {
                    label: "Distância",
                    result: 0,
                    amount: amountDistance,
                  },
                  bpa: {
                    label: "BPA",
                    result: 0,
                    amount: amountBPA,
                  },
                  fidelidade: {
                    label: "Fidelidade",
                    result: 0,
                    amount: amountLoyalty,
                  },
                  brucelose: {
                    label: "Brucelose",
                    result: 0,
                    amount: amountBrucelose,
                  },
                  tuberculose: {
                    label: "Tuberculose",
                    result: 0,
                    amount: amountTuberculose,
                  },
                },
                totals: {
                  quality: totalQuality,
                  others: totalOthers,
                },
              },
              dairyAdditionalAmount,
              technicalAdditionalAmount,
              complementaryAmount: {
                total: 0.0,
                items: [],
                show: false,
              },
              lastFinalPrice: 0,
              rangeFinalPrice: this.getRangeFinalPrice(producer.routeId),
              lastVol: 0,
              qualityAmount,
              locked: this.locked,
              invoiceNumber,
              imported: true,
            });
          }
        })

        if (failed.length === data.length) {
          throw new Error("Nada importado");
        }

        if (failed.length > 0) {
          this.$snotify.warning([
            `${failed.length} itens não puderam ser importados`,
            ...failed.slice(0, 3)
          ].join("\r\n"));
        }

        this.$snotify.info("Salve para concluir a importação!");

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao importar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Action responsável por re-fazer as bonificações do período
     * @returns {Promise<void>}
     */
    async regenerate() {
      if (!_.isEmpty(this.producers)) {
        if (!(await this.$root.$confirm(
          'Atenção',
          `Esta ação irá descartar <b>TODOS</b> os dados preenchidos do período selecionado, independente dos filtros aplicados.<br>`,
          { color: 'red', token: 'RESETAR' }
        ))
        ) {
          return;
        }
      }
      try {
        if (!this.hasRecountAccess) {
          throw 'Ação não permitida!';
        }

        this.loading = true;

        const data = await this.$socket.proxy({
          method: 'post',
          url: '/tabelaPreco/inicializarBonificacao',
          data: {
            mes_referencia: this.filters.date,
            tipo: this.rawMaterialType,
            id_materia_prima: this.rawMaterialId,
          },
        });

        if (!data.codigo) {
          throw data.mensagem;
        }

        this.loadBonusList();
        this.loadFilters();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Action responsável por salvar as bonificações que estão na tela
     * @returns {Promise<void>}
     */
    async save() {
      try {
        this.loading = true;

        const bonificacao = this.producers.map(bonus => {

          if ((bonus.locked || bonus.financialLocked || this.isFinalPriceOutOfRange(bonus)) && !this.hasSaveAccess) {
            return;
          }

          let bonificacao = {};

          if (bonus.imported) {
            bonificacao = {
              data_referencia: moment(this.filters.date, "YYYY-MM").format("YYYY-MM-01"),
              id_produtor: bonus.producer.id,
              nome_produtor: bonus.producer.name,
              codigo_laticinio: bonus.producer.code,

              valor_base: bonus.baseAmount,
              volume_resultado: bonus.vol,

              // Qualidade
              valor_adicional_ccs: bonus.result.quality.ccs.amount,
              valor_adicional_cbt: bonus.result.quality.cpp.amount,
              valor_adicional_esd: bonus.result.quality.esd.amount,
              valor_adicional_proteina: bonus.result.quality.proteina.amount,
              valor_adicional_gordura: bonus.result.quality.gordura.amount,
              valor_adicional_caseina: bonus.result.quality.caseina.amount,
              valor_adicional_ureia: bonus.result.quality.ureia.amount,
              valor_adicional_solidos_totais: bonus.result.quality.solidosTotais.amount,
              valor_adicional_lactose: bonus.result.quality.lactose.amount,
              valor_adicional_ccs_trimestral: bonus.result.quality.ccsTrimestral.amount,
              valor_adicional_cbt_trimestral: bonus.result.quality.cppTrimestral.amount,

              // Outros
              valor_adicional_volume: bonus.result.others.volume.amount,
              valor_adicional_km: bonus.result.others.distancia.amount,
              valor_adicional_bpa: bonus.result.others.bpa.amount,
              valor_adicional_fidelidade: bonus.result.others.fidelidade.amount,
              valor_adicional_brucelose: bonus.result.others.brucelose.amount,
              valor_adicional_tuberculose: bonus.result.others.tuberculose.amount,

              // Totais
              valor_bonificacao_qualidade: bonus.result.totals.quality,
              valor_bonificacao_adicional: bonus.result.totals.others,

              valor_qualidade: bonus.qualityAmount,

              numero_nfe: bonus.invoiceNumber,
              importado: true,
            }
          }

          return {
            data_referencia: moment(this.filters.date, "YYYY-MM").format("YYYY-MM-01"),
            id_materia_prima: this.rawMaterialId,
            id_produtor: bonus.producer.id,
            nome_produtor: bonus.producer.name,
            codigo_laticinio: bonus.producer.code,
            id_formacao_valor_leite: bonus.id,
            status: Number(this.isLocked),
            valor_adicional1: bonus.dairyAdditionalAmount,
            valor_adicional2: bonus.technicalAdditionalAmount,
            valor_complementar: bonus.complementaryAmount.total,
            complementos: bonus.complementaryAmount.items.map(item => ({
              valor: item.amount,
              numero_nfe: item.invoice,
              id_complemento: item.id,
            })),
            percentual_despesas: bonus.govSupportPerc,
            valor_despesas: this.getRowGovSupportPrice(bonus),
            valor_unitario_final: this.getRowFinalPrice(bonus),
            valor_unitario_final_sem_incentivo: this.getRowFinalPriceWithoutGovSupport(bonus),
            valor_bonificacao_adicional: bonus.result.totals.others,
            valor_entrega: this.getRowTotalPrice(bonus),
            valor_frete_real: bonus.realFreightPrice,
            observacao: bonus.observation,
            alterado_valor_adicional1: bonus.dairyAdditionalAmountChanged,
            alterado_valor_adicional2: bonus.technicalAdditionalAmountChanged,
            alterado_valor_complementar: bonus.complementaryAmountChanged,
            alterado_valor_frete_real: bonus.realFreightPriceChanged,
            alterado_observacao: bonus.observationChanged,
            preco_destravado: !!bonus.originalLocked && !this.isLocked,
            ...bonificacao,
          }
        }).filter(b => b);

        if (_.isEmpty(bonificacao)) {
          return;
        }

        const { data } = await this.$axios.post(
          `/tabelaPreco/salvaBonificacao`,
          bonificacao,
        );

        if (!_.isObject(data) || !data.codigo) {
          throw "PHP Error";
        }

        this.$snotify.success("Sucesso, dados salvos!");

        return this.loadBonusList();
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar!", "Atenção");

        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Action responsável por salvar as bonificações e bloquear a edição para técnicos
     * @returns {Promise<void>}
     */
    async saveAndLock() {
      this.isLocked = !this.isLocked;

      return await this.save();
    },

    /**
     * Converte todos os registros da lista para o excel
     */
    reportToXLS() {
      try {
        this.loading = true;

        const records = this.producers.map(producer => {

          const cols = this.$refs.report.filteredHeaders.map(header => {

            if (header.value === 'finalPrice') {
              return {
                key: header.text,
                value: this.getRowFinalPrice(producer),
              }
            }

            if (header.value === 'total') {
              return {
                key: header.text,
                value: this.getRowTotalPrice(producer),
              }
            }

            if (header.value === 'complementaryAmount') {
              return {
                key: header.text,
                value: producer.complementaryAmount.total,
              }
            }

            if (header.value === 'govSupportPrice') {
              return {
                key: header.text,
                value: this.getRowGovSupportPrice(producer),
              }
            }

            if (header.value === 'netPrice') {
              return {
                key: header.text,
                value: this.getNetPrice(producer),
              }
            }

            if (header.value === 'platformPrice') {
              return {
                key: header.text,
                value: this.getPlatformPrice(producer),
              }
            }

            if (header.value === 'platformTotalPrice') {
              return {
                key: header.text,
                value: this.getPlatformTotalPrice(producer),
              }
            }

            if (header.value === 'rangeFinalPrice') {
              return {
                key: header.text,
                value: 'R$ '  + producer.rangeFinalPrice.min + ' - R$ ' + producer.rangeFinalPrice.max,
              }
            }

            if (header.value === 'finalPriceDiff') {
              return {
                key: header.text,
                value: this.getRowFinalPriceDiff(producer),
              }
            }

            if (header.value === 'finalPriceWithoutGovSupport') {
              return {
                key: header.text,
                value: this.getRowFinalPriceWithoutGovSupport(producer),
              }
            }

            if (header.value === 'finalPriceWithoutComplementary') {
              return {
                key: header.text,
                value: this.getRowFinalPriceWithoutComplementary(producer),
              }
            }

            if (header.value === 'finalPriceWithoutComplementaryDiff') {
              return {
                key: header.text,
                value: this.getRowFinalPriceWithoutComplementaryDiff(producer),
              }
            }

            return {
              key: header.text,
              value: _.get(producer, header.value),
            }
          });

          const mapped = cols.map(item => ({ [item.key]: item.value }) );

          return Object.assign({}, ...mapped );
        });

        const data = XLSX.utils.json_to_sheet(records);
        const workbook = XLSX.utils.book_new();
        const filename = `Bonificações - ${moment(this.filters.date, 'YYYY-MM').format('MM-YYYY')}`;

        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } catch (e) {

        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    onDateFilterChange() {
      this.loadFilters();
      this.onFilterChange();
    },

    /**
     * @event void
     *
     * Evento acionado para refazer a lista com base nos novos filtros
     */
    onFilterChange: _.debounce(function() {
      if (this.isAProjection) {
        this.loadProjection();
      }
      else {
        this.loadBonusList();
      }
    }, 1000),

    onDataTablePaginationChange() {
      this.$nextTick(() => {
        return this.autoFocusFirst();
      });
    },

    autoFocusFirst() {
      let first = {};

      if (this.isAdmin) {
        first = _.first(_.filter(this.$refs, (value, key) => {
          return key.includes('dairyAdditionalAmount') && !_.isEmpty(value);
        }));
      } else {
        first = _.first(_.filter(this.$refs, (value, key) => {
          return key.includes('technicalAdditionalAmount') && !_.isEmpty(value);
        }));
      }

      if (!first) {
        return;
      }

      return first.focus();
    },

    onInputEnter(ref, index) {
      const elm = this.$refs[`${ref}-${++index}`]

      if (_.isEmpty(elm)) {
        return;
      }

      return elm.focus();
    },

    onRowPriceChanged(item, input) {
      this.producers = this.producers.map(producerBonus => {

        if (producerBonus.id !== item.id) {
          return producerBonus;
        }

        return {
          ...producerBonus,
          [`${input}Changed`]: true,
        }
      });
    },

    onGroupPriceChanged(group, input) {
      const value = this.groups[group][input];

      this.producers = this.producers.map(producerBonus => {
        if (producerBonus.producer.group !== group) {
          return producerBonus;
        }

        return {
          ...producerBonus,
          [input]: value,
          [`${input}Changed`]: true,
        }
      });
    },

    /**
     * Recupera o valor final do preço do leite
     * @param item {Object}
     * @returns {Number}
     */
    getRowFinalPriceWithoutGovSupport(item) {
      return (
        item.baseAmount
        +
        item.result.totals.quality
        +
        item.result.totals.others
        +
        (parseFloat(item.dairyAdditionalAmount) || 0)
        +
        (parseFloat(item.technicalAdditionalAmount) || 0)
        +
        (parseFloat(item.complementaryAmount.total) || 0)
      );
    },

    getRowGovSupportPrice(item) {
      const finalPrice = this.getRowFinalPriceWithoutGovSupport(item);

      const govSupportPerc = parseFloat(item.govSupportPerc) || 0;

      if (govSupportPerc) {
        return (finalPrice * (govSupportPerc / 100))
      }

      return 0;
    },

    getRowFinalPrice(item, sorting = false) {

      if (sorting) {
        item =  this.producers[item];
      }

      const amountWithoutGovSupport = this.getRowFinalPriceWithoutGovSupport(item);
      const govSupportPrice = this.getRowGovSupportPrice(item);

      return amountWithoutGovSupport + govSupportPrice;
    },

    /**
     * Recupera o valor final a pagar
     * @param item {Object}
     * @returns {Number}
     */
    getRowTotalPrice(item) {
      if (item.govSupportPerc) {
        return item.vol * this.getRowFinalPrice(item);
      }

      // Fixa em 4 casas decimais o cálculo
      return item.vol * parseFloat(this.getRowFinalPrice(item).toFixed(4));
    },

    getRowFinalPriceDiff(item, sorting = false) {
      if (sorting) {
        // Para a ordenação do data-table, é usado o indíce para obter o item
        item =  this.producers[item];
      }

      const lastFinalPrice = parseFloat(item.lastFinalPrice);
      const finalPrice = this.getRowFinalPrice(item);
      const diff = finalPrice - lastFinalPrice;

      if (sorting) {
        return diff;
      }

      const masked = this.$options.filters.Mask(Math.abs(diff).toFixed(4), this.NumberMask);

      if (diff > 0) {
        return `(+) ${masked}`;
      }

      return `(-) ${masked}`;
    },

    getRowFinalPriceWithoutComplementary(item, sorting = false) {
      if (sorting) {
        // Para a ordenação do data-table, é usado o indíce para obter o item
        item =  this.producers[item];
      }
      return this.getRowFinalPrice(item) - (parseFloat(item.complementaryAmount.total) || 0);
    },

    getRowFinalPriceWithoutComplementaryDiff(item, sorting = false) {
      if (sorting) {
        // Para a ordenação do data-table, é usado o indíce para obter o item
        item =  this.producers[item];
      }

      const lastFinalPrice = parseFloat(item.lastFinalPriceWithoutComplementary);
      const finalPrice = this.getRowFinalPriceWithoutComplementary(item);
      const diff = finalPrice - lastFinalPrice;

      if (sorting) {
        return diff;
      }

      const masked = this.$options.filters.Mask(Math.abs(diff).toFixed(4), this.NumberMask);

      if (diff > 0) {
        return `(+) ${masked}`;
      }

      return `(-) ${masked}`;
    },

    /**
     * Calcula o valor líquido da cotação
     */
    getNetPrice(item) {
      if (item.taxAmount || item.freightAmount) {
        return (this.getRowTotalPrice(item) - item.taxAmount - item.freightAmount) / item.vol;
      }

      return this.getRowFinalPrice(item);
    },

    getPlatformPrice(item) {
      const netPrice = this.getNetPrice(item);

      const realFreightPrice = item.realFreightPrice || 0.0;

      return netPrice + realFreightPrice;
    },

    getPlatformTotalPrice(item) {
      const platformPrice = this.getPlatformPrice(item);

      return item.vol * platformPrice;
    },

    showProducersAdditionalsAdjustimentDialog() {
      return this.$refs.producersAdditionalsAdjustment.show();
    },

    onAdditionalAdjust({ column, operation, amount }) {
      this.producers = this.producers.map(producer => {

        // Adiciona ou diminui a depender da operação no formulário
        let adjustmentAmount = operation === 'increase' ? amount : amount * -1;

        if (operation === 'difference') {
          adjustmentAmount = this.getRowFinalPriceDiff(producer.finalPriceDiff, true) * -1;
        }

        const oldAmount = parseFloat(producer[column]);
        const newAmount = oldAmount + adjustmentAmount;

        producer[column] = newAmount.toFixed(4);
        producer[`${column}Changed`] = true;

        return producer;
      });
    },

    showProducerFinalPriceAdjustimentDialog({ id }) {
      return this.$refs.producerFinalPriceAdjustment.show(id);
    },

    onFinalPriceAdjust({ id, column, amount }) {
      const index = this.producers.findIndex((item) => item.id === id);

      const producer = this.producers[index];

      const currentFinalPrice = this.getRowFinalPrice(producer);

      const adjustmentAmount = amount - currentFinalPrice

      const oldAmount = parseFloat(producer[column]);
      const newAmount = oldAmount + adjustmentAmount;

      this.producers.splice(index, 1, ({
        ...producer,
        [column]: newAmount.toFixed(4),
        [`${column}Changed`]: true,
      }));
    },

    showProducerBonusObservationDialog(bonus) {
      return this.$refs.producerBonusObservationDialog.show(bonus);
    },

    onProducerBonusObservationSave({ id, observation }) {
      const index = this.producers.findIndex((item) => item.id === id);

      const producer = this.producers[index];

      this.producers.splice(index, 1, ({
        ...producer,
        observation,
        [`observationChanged`]: true,
      }));
    },

    async loadProducers() {
      try {
        const { data } = await this.$axios.post(`/produtores/listaJson`);

        if (_.isString(data)) {
          throw data;
        }

        this.persons = data.map(person => ({
          id: person.id_pessoa,
          name: person.nome,
          code: person.codigo_laticinio,
          status: person.status,
          routeId: person.id_rota,
        }));
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    /**
     * @void
     */
    recalculatePrices() {

      if (this.recalculatoredByLastMonthPrice) return  false;

      this.producers = this.producers.map(item => {
        const price = (item.lastFinalPrice - item.baseAmount) || 0.0;

        return {
          ...item,
          technicalAdditionalAmount: price > 0 ? price : 0
        };
      });

      this.recalculatoredByLastMonthPrice = !this.recalculatoredByLastMonthPrice;
    },

    applyMask: (value, mask) => IMask.pipe(value, mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED),

    formatNumber: (value, decimals) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: decimals }).format(value),

    formatCurrency: (value, decimals = 4) => {
      if (_.isNull(value)) {
        return '-';
      }

      const formatter = new Intl.NumberFormat(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
          maximumFractionDigits: decimals
        }
      );

      return formatter.format(value || 0);
    },

    showProducerBonusLog(row) {
      return this.$refs.producerBonusLogDialog.show(row);
    },

    calculateRealFreight() {
      return this.$refs.producersRealFreightDialog.show({
        monthReference: this.filters.date,
      });
    },

    onRealFreightConfirm(table) {
      this.producers = this.producers.map(bonus => ({
        ...bonus,
        realFreightPrice: table[bonus.producer.routeId] || 0,
        realFreightPriceChanged: true,
      }))
    },

    async loadLastFinalPriceAvg() {
      try {
        this.loading = true;

        const dateRef = moment(this.filters.date, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM');

        const { data } = await this.$axios.post(
          `/tabelaPreco/precoMedioPeloVolume`,
          {
            mes_referencia: dateRef,
            tipo: this.rawMaterialType,
            id_materia_prima: this.rawMaterialId,
            id_rota: this.filters.route,
            id_usuario: this.filters.person,
            id_regiao: this.filters.region,
            tipo_produtor: this.filters.producerType,
          }
        );

        this.lastMilkPriceAvg = this.priceAvgByNetPrice ? _.get(data, 'valor_liquido', 0) : _.get(data, 'valor', 0);
        this.lastTotalVol = _.get(data, 'volume', 0);
        this.lastPlatformCostAvg = _.get(data, 'custo_plataforma', 0);
      } catch (e) {

        this.$snotify.error("Oops, ocorreu um erro ao carregar o preço médio anterior!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadFilters() {
      try {
        const { data } = await this.$axios.post(`/tabelaPreco/filtrosBonificacao`, {
          mes_referencia: this.filters.date,
          tipo: this.rawMaterialType,
          id_materia_prima: this.rawMaterialId,
        });

        this.filters.routes = data.rotas;
        this.filters.regions = data.regioes;
        this.filters.persons = data.tecnicos;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os filtros!", 'Atenção');
      }
    },

    customSort(items, sortBy, sortDesc, locale, customSorters) {
      const customColumns = ['vol']

      if (!this.groupBy || sortBy.length < 2 || !customColumns.includes(sortBy[1])) {
        return sortItems(items, sortBy, sortDesc, 'pt-BR', customSorters);
      }

      const sortKey = sortBy[1];
      const isDesc = sortDesc[0];

      // Primeiro ordena pela coluna padrão
      items = sortItems(items, [sortKey], sortDesc, 'pt-BR', customSorters);

      const getItemSort = (item) => {
        if (item.producer.group) {
          return this.groupTotals[item.producer.group][sortKey];
        }

        return item[sortKey]
      }

      // Depois ordena pela coluna do grupo (será priorizada)
      items = items.sort((a, b) => {
        let sortA = getItemSort(a)
        let sortB = getItemSort(b)

        if (isDesc) {
          [sortA, sortB] = [sortB, sortA]
        }

        if (sortKey === 'vol') {
          return sortA - sortB
        }

        return sortA.localeCompare(sortB)
      })

      // Separa os itens de grupo e os que não são de grupo
      const [group, common] = items.reduce(([group, common], item) => {
        if (item.producer.group) {
          group.push(item);
        } else {
          common.push(item);
        }
        return [group, common];
      }, [[], []]);

      if (isDesc) {
        return [...group, ...common]
      }

      return [...common, ...group]
    },

    getDisabledStyle() {
      return { background: 'rgba(255, 255, 255, 0.12) !important', cursor: 'not-allowed' }
    },

    getValueIfAllEqual: ([first, ...others], column = undefined) => {
      const getValue = item => column ? _.get(item, column) : item

      const allEqual = others.every(other => getValue(other) === getValue(first))

      if (allEqual) {
        return getValue(first)
      }

      return null
    },
  },
};
</script>
