<template>
  <v-dialog
    v-model="show"
    width="400"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Configurações de Frete
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="isValid"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-list-item
                two-line
                class="px-0"
              >
                <v-list-item-content class="pb-0">
                  <v-list-item-title class="caption">
                    Usar config. de tolerância da Descarga
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-switch
                      v-model="form.unloadTolerance"
                      :label="form.unloadTolerance ? 'Ativo' : 'Inativo'"
                      prepend-inner-icon="receipt"
                      hide-details
                      class="mt-0"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="form.toleranceType"
                label="Tipo de Tolerância *"
                :items="[
                  { text: 'Percentual Geral', value: 'PERCENTUAL' },
                  { text: 'Passe/Falta', value: 'PASS_OR_LACK' },
                ]"
                :disabled="form.unloadTolerance"
                :rules="!form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []"
              />
            </v-col>
            <v-col
              v-if="form.toleranceType === 'PASS_OR_LACK'"
              cols="12"
            >
              <masked-text-field
                v-model="form.passOrLackTolerance"
                :label="`Tolerância Passe/Falta ${!form.unloadTolerance ? '*' : ''}`"
                sufix="L"
                :mask="{ mask: Number, min: 0 }"
                unmask
                :disabled="form.unloadTolerance"
                :rules="!form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []"
              />
            </v-col>
            <v-col
              v-else
              cols="12"
            >
              <masked-text-field
                v-model="form.percentualTolerance"
                :label="`Tolerância Geral ${!form.unloadTolerance ? '*' : ''}`"
                prefix="%"
                :mask="{ mask: Number, min: 0, max: 100 }"
                unmask
                :disabled="form.unloadTolerance"
                :rules="!form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []"
              />
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-list-item
                two-line
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title class="caption">
                    Considerar máx. da faixa anterior como Frete Mínimo
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-switch
                      v-model="form.respectMinimumFreight"
                      :label="form.respectMinimumFreight ? 'Ativo' : 'Inativo'"
                      prepend-inner-icon="receipt"
                      hide-details
                      class="mt-0"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="form.milkPriceType"
                label="Valor Litro do Leite para Cálculo de Descontos"
                :items="[
                  { text: 'Média Geral', value: 'MEDIA_GERAL' },
                  { text: 'Média Rota(s)', value: 'MEDIA_ROTA' },
                ]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          class="black--text"
          outlined
          @click="cancel()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          class="blue--text"
          outlined
          :loading="saving"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="saving"
      absolute
    >
      <v-card-text>
        Salvando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import get from 'lodash/get';
import MaskedTextField from '@/Support/Components/MaskedTextField.vue';

export default {

  components: {
    MaskedTextField,
  },

  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      saving: false,
      isValid: true,
      form: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(value) {
      if (value) {
        this.$refs.form && this.$refs.form.resetValidation();
        this.form = {
          unloadTolerance: this.$store.state.settings.gerais.frete_tolerancia_descarga,
          toleranceType: this.$store.state.settings.gerais.frete_tolerancia_passe_falta ? 'PASS_OR_LACK' : 'PERCENTUAL',
          percentualTolerance: this.$store.state.settings.gerais.frete_tolerancia_percentual,
          passOrLackTolerance: this.$store.state.settings.gerais.frete_tolerancia_passe_falta,
          respectMinimumFreight: this.$store.state.settings.gerais.considerar_frete_minimo,
          milkPriceType: this.$store.state.settings.gerais.frete_tipo_valor_leite || 'MEDIA_GERAL',
        }
      }
    }
  },

  methods: {
    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      const dairyId = get(this.$store.state.settings, 'laticinio.id');

      if (!dairyId) {
        this.$snotify.error('Escolha o laticínio!', 'Atenção');
        return;
      }

      try {
        this.saving = true;

        await this.$axios.put(`/settings/dairy/${dairyId}`, {
          frete_tolerancia_descarga: this.form.unloadTolerance,
          frete_tolerancia_percentual: this.form.percentualTolerance,
          frete_tolerancia_passe_falta: this.form.passOrLackTolerance,
          considerar_frete_minimo: this.form.respectMinimumFreight,
          frete_tipo_valor_leite: this.form.milkPriceType,
        });

        this.$store.dispatch('updateStoreSetting');
        this.$snotify.success('Configuração salva com sucesso!', 'Sucesso');
        this.show = false;
      } catch (error) {
        const message = get(error, 'response.data.message', 'Erro ao salvar configuração');
        this.$snotify.error(message);
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    cancel() {
      this.show = false;
    }
  }
}
</script>
