var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"400","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Configurações de Frete "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":"","depressed":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" close ")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',{staticClass:"pb-0"},[_c('v-list-item-title',{staticClass:"caption"},[_vm._v(" Usar config. de tolerância da Descarga ")]),_c('v-list-item-subtitle',[_c('v-switch',{staticClass:"mt-0",attrs:{"label":_vm.form.unloadTolerance ? 'Ativo' : 'Inativo',"prepend-inner-icon":"receipt","hide-details":""},model:{value:(_vm.form.unloadTolerance),callback:function ($$v) {_vm.$set(_vm.form, "unloadTolerance", $$v)},expression:"form.unloadTolerance"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Tipo de Tolerância *","items":[
                { text: 'Percentual Geral', value: 'PERCENTUAL' },
                { text: 'Passe/Falta', value: 'PASS_OR_LACK' },
              ],"disabled":_vm.form.unloadTolerance,"rules":!_vm.form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []},model:{value:(_vm.form.toleranceType),callback:function ($$v) {_vm.$set(_vm.form, "toleranceType", $$v)},expression:"form.toleranceType"}})],1),(_vm.form.toleranceType === 'PASS_OR_LACK')?_c('v-col',{attrs:{"cols":"12"}},[_c('masked-text-field',{attrs:{"label":`Tolerância Passe/Falta ${!_vm.form.unloadTolerance ? '*' : ''}`,"sufix":"L","mask":{ mask: Number, min: 0 },"unmask":"","disabled":_vm.form.unloadTolerance,"rules":!_vm.form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []},model:{value:(_vm.form.passOrLackTolerance),callback:function ($$v) {_vm.$set(_vm.form, "passOrLackTolerance", $$v)},expression:"form.passOrLackTolerance"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('masked-text-field',{attrs:{"label":`Tolerância Geral ${!_vm.form.unloadTolerance ? '*' : ''}`,"prefix":"%","mask":{ mask: Number, min: 0, max: 100 },"unmask":"","disabled":_vm.form.unloadTolerance,"rules":!_vm.form.unloadTolerance ? [v => !!v || 'Campo obrigatório'] : []},model:{value:(_vm.form.percentualTolerance),callback:function ($$v) {_vm.$set(_vm.form, "percentualTolerance", $$v)},expression:"form.percentualTolerance"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"caption"},[_vm._v(" Considerar máx. da faixa anterior como Frete Mínimo ")]),_c('v-list-item-subtitle',[_c('v-switch',{staticClass:"mt-0",attrs:{"label":_vm.form.respectMinimumFreight ? 'Ativo' : 'Inativo',"prepend-inner-icon":"receipt","hide-details":""},model:{value:(_vm.form.respectMinimumFreight),callback:function ($$v) {_vm.$set(_vm.form, "respectMinimumFreight", $$v)},expression:"form.respectMinimumFreight"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Valor Litro do Leite para Cálculo de Descontos","items":[
                { text: 'Média Geral', value: 'MEDIA_GERAL' },
                { text: 'Média Rota(s)', value: 'MEDIA_ROTA' },
              ]},model:{value:(_vm.form.milkPriceType),callback:function ($$v) {_vm.$set(_vm.form, "milkPriceType", $$v)},expression:"form.milkPriceType"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"black--text",attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" cancelar ")]),_c('v-spacer'),_c('v-btn',{staticClass:"blue--text",attrs:{"outlined":"","loading":_vm.saving},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.saving,"absolute":""}},[_c('v-card-text',[_vm._v(" Salvando... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }