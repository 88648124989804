<template>
  <v-container
    text-xs-center
    grid-list-lg
  >
    <v-row>
      <v-col cols="12">
        <h2
          class="menu-header white--text"
        >
          Controle de Estoque por Lote
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="3"
                class="pt-0"
              >
                <warehouse-select
                  v-model="filter.warehouseId"
                  label="Depósito"
                  filled
                  hide-details
                  auto-select-default
                  @change="loadProducts"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="filter.search"
                  append-icon="search"
                  label="Buscar"
                  filled
                  single-line
                  hide-details
                  dark
                />
              </v-col>
            </v-row>
          </v-card-title>
          <data-table
            ref="report"
            :headers="headers"
            :items="filteredItems"
            dark
            group-by="productName"
          >
            <template #[`group.header`]="{ group, isOpen, toggle, headers, items }">
              <td
                v-for="(h, idx) in headers"
                :key="h.value"
                :class="h.align ? `text-${h.align}` : 'text-start'"
              >
                <template v-if="h.value === 'data-table-expand'">
                  <v-btn
                    icon
                    @click="toggle"
                  >
                    <v-icon>
                      {{ isOpen ? 'remove' : 'add' }}
                    </v-icon>
                  </v-btn>
                </template>
                <template v-else-if="idx === 1">
                  <b v-if="group">{{ group }}</b>
                </template>
                <template v-else-if="h.value === 'quantity'">
                  <v-chip
                    small
                    :color="getQuantityColor(totals[group]?.quantity)"
                  >
                    {{ formatNumber(totals[group]?.quantity) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'packagedQuantity'">
                  <v-chip
                    small
                    :color="getQuantityColor(totals[group]?.packagedQuantity)"
                  >
                    {{ formatNumber(totals[group]?.packagedQuantity) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'notPackagedQuantity'">
                  <v-chip
                    small
                  >
                    {{ formatNumber(totals[group]?.notPackagedQuantity) }}
                    <span class="ml-1">
                      {{ items[0].product.measurement }}
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'grossWeight'">
                  <v-chip
                    small
                    color="blue darken-2"
                  >
                    {{ formatNumber(totals[group]?.grossWeight) }}
                    <span class="ml-1">
                      Kg
                    </span>
                  </v-chip>
                </template>
                <template v-else-if="h.value === 'packagedGrossWeight'">
                  <v-chip
                    small
                    color="blue darken-2"
                  >
                    {{ formatNumber(totals[group]?.packagedGrossWeight) }}
                    <span class="ml-1">
                      Kg
                    </span>
                  </v-chip>
                </template>
              </td>
            </template>

            <template #[`item.quantity`]="{ value, item }">
              <v-chip
                dark
                small
              >
                {{ formatNumber(value) }}
                <span class="ml-1">
                  {{ item.product.measurement }}
                </span>
              </v-chip>
            </template>

            <template #[`item.grossWeight`]="{ value, item }">
              <v-chip
                v-if="value"
                dark
                small
              >
                {{ formatNumber(value) }}
                <span class="ml-1">
                  Kg
                </span>
              </v-chip>
            </template>

            <template #[`item.packagedQuantity`]="{ value, item }">
              <v-chip
                dark
                small
              >
                {{ formatNumber(value) }}
                <span class="ml-1">
                  {{ item.product.measurement }}
                </span>
              </v-chip>
            </template>

            <template #[`item.notPackagedQuantity`]="{ value, item }">
              <v-chip
                dark
                small
              >
                {{ formatNumber(value) }}
                <span class="ml-1">
                  {{ item.product.measurement }}
                </span>
              </v-chip>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script setup>
import { ref, computed, reactive } from 'vue'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'

import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import WarehouseSelect from '@/Domains/Registrations/Item/Components/WarehouseSelect.vue'

const { progressBar, notify } = useUtils()

const filter = reactive({
  warehouseId: null,
  search: null
})

const headers = [
  { text: 'Produto', value: 'productName' },
  { text: 'Lote', value: 'lotNumber' },
  { text: 'Fabricação', value: 'manufacturingDate', align: 'center', formatter: value => formatDate(value, 'DD/MM/YYYY') },
  { text: 'Validade', value: 'expirationDate', align: 'center', formatter: value => formatDate(value, 'DD/MM/YYYY') },
  { text: 'Qtde. Estoque', value: 'quantity', formatter: value => formatNumber(value), mask: '#,##0.0' },
  { text: 'Qtde. Embalada', value: 'packagedQuantity', formatter: value => formatNumber(value), mask: '#,##0.0' },
  { text: 'Qtde. Não Embalada', value: 'notPackagedQuantity', formatter: value => formatNumber(value), mask: '#,##0.0' },
  // { text: 'Peso Estoque', value: 'grossWeight', formatter: value => formatNumber(value) + ' Kg', mask: '#,##0.0' },
  { text: 'Peso Embalado', value: 'packagedGrossWeight', formatter: value => formatNumber(value) + ' Kg', mask: '#,##0.0' },
]

const report = ref()
const items = ref([])
const totals = ref({})

const filteredItems = computed(() => {
  if (!filter.search) {
    return items.value
  }

  const search = filter.search.toUpperCase().trim()

  return items.value.filter(item => {
    const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search)
    return hasSearch
  })
})

const loadProducts = async () => {
  try {
    progressBar?.loading()

    const { data } = await axios.get(`/stock`, { params: {
      id_deposito: filter.warehouseId,
      apenas_lotes_industria: 1,
      exibir_embalagens: 1,
    } })

    items.value = data
      .filter(stock => !!stock.item)
      .map(stock => ({
        productName: `${stock.item.cod_item} - ${stock.item.nome}`,
        product: {
          id: stock.item.id_item,
          code: stock.item.cod_item,
          name: stock.item.nome,
          measurement: stock.item.unidade,
          grossWeight: stock.item.peso_bruto,
        },
        lotNumber: stock.numero_lote,
        lotStatus: stock.status_lote,
        manufacturingDate: stock.data_fabricacao,
        expirationDate: stock.data_validade,
        quantity: stock.quantidade,
        packagedQuantity: parseFloat(stock.quantidade_embalado) || 0,
        notPackagedQuantity: stock.quantidade_embalado < stock.quantidade ? stock.quantidade - (parseFloat(stock.quantidade_embalado) || 0) : 0,
        grossWeight: stock.quantidade > 0 && stock.item.peso_bruto ? (stock.quantidade * stock.item.peso_bruto) : null,
        packagedGrossWeight: parseFloat(stock.peso_bruto_embalado) || 0,
      }))

    const group = groupBy(items.value, 'productName')

    totals.value = mapValues(group, (items) => {
      return {
        quantity: items.filter(o => o.quantity).reduce((acc, cur) => acc + cur.quantity, 0),
        packagedQuantity: items.filter(o => o.packagedQuantity).reduce((acc, cur) => acc + cur.packagedQuantity, 0),
        notPackagedQuantity: items.filter(o => o.notPackagedQuantity).reduce((acc, cur) => acc + cur.notPackagedQuantity, 0),
        grossWeight: items.filter(o => o.grossWeight).reduce((acc, cur) => acc + cur.grossWeight, 0),
        packagedGrossWeight: items.filter(o => o.packagedGrossWeight).reduce((acc, cur) => acc + cur.packagedGrossWeight, 0),
      }
    })
  } catch (error) {
    const message = error?.response?.data?.message || 'Erro ao carregar estoque'
    notify.error(message, 'Atenção')
    console.warn(error)
  } finally {
    progressBar?.hide()
  }
}

const exportExcel = () => {
  report.value?.exportExcel()
}

const print = () => {
  report.value?.print()
}

const getQuantityColor = (value) => {
  if (value < 0) {
    return 'red darken-1'
  }
  if (value > 0) {
    return 'green darken-1'
  }
  return 'grey'
}

const formatDate = (value, format) => !value ? '-' : moment(value).format(format)
const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)

loadProducts()
</script>
